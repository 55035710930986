import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import contactApi from "@/services/api/contactApi";

const state = reactive({
  unwatchedLeadsCounter: 0
});

export type contactState = typeof state;

const contactStore: Module<contactState, never> = {
  namespaced: true,
  state,
  mutations: {
    setUnwatchedLeads(state, data): void {
      state.unwatchedLeadsCounter = data;
    }
  },
  actions: {
    getContacts(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.getContacts(payload);
    },
    getLeads(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.getLeads(payload);
    },
    getLead(_, id: string): Promise<AxiosResponse> {
      return contactApi.getLead(id);
    },
    createLead(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.createLead(payload);
    },
    updateLead(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.updateLead(payload);
    },
    bulkDeleteLeads(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.bulkDeleteLeads(payload);
    },
    bulkChangeLeadsStatuses(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.bulkChangeLeadsStatuses(payload);
    },
    shareEoiLinkWithContacts(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.shareEoiLinkWithContacts(payload);
    },
    shareLeadsLinks(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.shareLeadsLinks(payload);
    },
    importLeads(_, payload = {}): Promise<AxiosResponse> {
      return contactApi.importLeads(payload);
    },
    downloadLeadsTemplate(): Promise<AxiosResponse> {
      return contactApi.downloadLeadsTemplate();
    },
    uploadLeadsTemplate(_, data: FormData): Promise<AxiosResponse> {
      return contactApi.uploadLeadsTemplate(data);
    },
    deleteLeadsTemplate(): Promise<AxiosResponse> {
      return contactApi.deleteLeadsTemplate();
    },
    getEnquiries(_, data = {}): Promise<AxiosResponse> {
      return contactApi.getEnquiries(data);
    },
    getUnwatchedLeads({ commit }, estateId: string): Promise<AxiosResponse> {
      return contactApi.getUnwatchedLeads(estateId).then((res) => {
        commit("setUnwatchedLeads", res.data);
        return res.data;
      });
    },
    watchedLead({ dispatch }, payload = {}): Promise<AxiosResponse> {
      return contactApi.watchedLead(payload)
        .then((res) => {
          dispatch("getUnwatchedLeads", payload.estateId);
          return res;
        });
    },
    sendReservationLinkToNewLead(_, data = {}): Promise<AxiosResponse> {
      return contactApi.sendReservationLinkToNewLead(data);
    },
  },
};

export default contactStore;
