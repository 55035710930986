import { removeEmptyEntries } from "@/helpers/removeEmptyEntries";
import axiosClient from "@/services/api/index";
import { BuilderCreateType, BuilderInterface } from "@/types/BuilderInterface";
import { BuilderQuote, UpdateBuilderQuoteDto } from "@/types/BuilderQuote";
import { CreateBuilderEnquiry } from "@/types/CreateBuilderEnquiry";
import { CreateBuilderQuote } from "@/types/CreateBuilderQuote";
import {
  HouseDesignCreateType,
  HouseDesignInterface,
} from "@/types/HouseDesignInterface";
import { HouseFiltersFields } from "@/types/HouseFilterFieldsInterface";
import { CreateInitialHoldInterface } from "@/types/InitialHoldInterface";
import { AxiosResponse } from "axios";

export default {
  getBuildersByLotId(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`builder/lot/${id}`);
  },
  getBuilders(query: { name: string }): Promise<AxiosResponse> {
    const params = removeEmptyEntries({
      name: query.name,
    });
    return axiosClient.get(`builder/list/all`, { params });
  },
  getAllBuilderWithHouses(): Promise<AxiosResponse> {
    return axiosClient.get("builder/list/all/houses");
  },
  createBuilder(builder: BuilderCreateType): Promise<AxiosResponse> {
    return axiosClient.post("builder", builder);
  },
  uploadLogo(logoData: FormData): Promise<AxiosResponse> {
    return axiosClient.post("builder/logo", logoData);
  },
  getBuilderById(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`builder/${id}`);
  },
  updateBuilder(builder: BuilderInterface): Promise<AxiosResponse> {
    return axiosClient.patch(`builder/${builder._id}`, builder);
  },
  getBuilderWithHouses(builderId: string): Promise<AxiosResponse> {
    return axiosClient.get(`builder`, {
      params: {
        builders: [builderId],
      },
    });
  },
  getSiteCost(
    lotId: string,
    builderId: string,
    houseId: string
  ): Promise<AxiosResponse> {
    return axiosClient.get(
      `builder/lot/${lotId}/builder/${builderId}/house/${houseId}/sitecost`
    );
  },
  deleteBuilder(builderId: string): Promise<AxiosResponse> {
    return axiosClient.delete(`builder/${builderId}`);
  },
  getHousesByBuilderId({
    builderId,
    name = "",
  }: {
    builderId: string;
    name: string;
  }): Promise<AxiosResponse> {
    return axiosClient.get(`house/builder/${builderId}`, { params: { name } });
  },
  deleteHouse(houseId: string): Promise<AxiosResponse> {
    return axiosClient.delete(`house/${houseId}`);
  },
  sendQuote(data: CreateBuilderQuote): Promise<AxiosResponse> {
    return axiosClient.post("builder-quote", data);
  },
  securePackage(
    quoteId: string,
    data: CreateBuilderQuote
  ): Promise<AxiosResponse> {
    return axiosClient.post(`builder-quote/secure-package/${quoteId}`, data);
  },
  setAsOpened(id: string): Promise<AxiosResponse> {
    return axiosClient.post(`builder-quote/open/${id}`);
  },
  getQuote(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`builder-quote/${id}`);
  },
  getQuoteWithDetails(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`builder-quote/${id}/details`);
  },
  getPackages(estateId: string | null): Promise<AxiosResponse> {
    const endpoint = estateId
      ? `builder-quote?estateId=${estateId}`
      : "builder-quote";
    return axiosClient.get(endpoint);
  },
  updatePackage(quoteId: string, data: BuilderQuote): Promise<AxiosResponse> {
    return axiosClient.patch(`builder-quote/${quoteId}`, data);
  },
  deletePackage(packageId: string): Promise<AxiosResponse> {
    return axiosClient.delete(`builder-quote/${packageId}`);
  },
  uploadHouseImages(
    images: FormData,
    builderId: string
  ): Promise<AxiosResponse> {
    return axiosClient.post(`house/images/${builderId}`, images);
  },
  uploadHouseFile(image: FormData, builderId: string): Promise<AxiosResponse> {
    return axiosClient.post(`house/inclusions/${builderId}`, image);
  },
  createHouse(house: HouseDesignCreateType): Promise<AxiosResponse> {
    return axiosClient.post("house", house);
  },
  getHousesByFilter(
    estateId: string,
    query: HouseFiltersFields
  ): Promise<AxiosResponse> {
    const params = {
      ...query,
    };
    return axiosClient.get(`house/builder-lots/estateId/${estateId}`, {
      params,
    });
  },
  getQuantitysBedroomsBathroomsStoreys(): Promise<AxiosResponse> {
    return axiosClient.get("house/quantities-bedrooms-bathrooms-storeys");
  },
  getLotsByEstateAndHouseDesign(
    estateId: string,
    houseId: string
  ): Promise<AxiosResponse> {
    return axiosClient.get(`house/lots/estate/${estateId}/house/${houseId}`);
  },
  getHouseById(houseId: string): Promise<AxiosResponse> {
    return axiosClient.get(`house/${houseId}`);
  },
  updateHouse(house: HouseDesignInterface): Promise<AxiosResponse> {
    return axiosClient.patch(`house/${house._id}`, house);
  },
  bulkHouseUpload(payload: {
    builderId: string;
    data: FormData;
  }): Promise<AxiosResponse> {
    return axiosClient.post(
      `house/bulk/upload/${payload.builderId}`,
      payload.data
    );
  },
  requestHoldPackage(
    quoteId: string,
    updateQuoteDto: UpdateBuilderQuoteDto,
    createHoldDto: CreateInitialHoldInterface
  ): Promise<AxiosResponse> {
    const data = {
      updateQuoteDto,
      createHoldDto,
    };
    return axiosClient.post(
      `builder-quote/request-hold-package/${quoteId}`,
      data
    );
  },
  enquiryBuilderPackage(data: CreateBuilderEnquiry): Promise<AxiosResponse> {
    return axiosClient.post("builder-quote/enquiry-builder-package", data);
  },
};
