import { reactive } from "vue";
import { Module } from "vuex";
import { CreateProfileDto, KlaviyoProfile, CreateEventDto, VerifyProfileDto, ValidatePhoneNumberDto, ResendCodeDto } from "@/types/KlaviyoInterface";
import { HttpResponseType, VerifyProfileResponseType } from "@/types/HttpResponseType";
import klaviyoApi from "@/services/api/klaviyoApi";

const state = reactive({
  profiles: [] as KlaviyoProfile[],
});

export type klaviyoState = typeof state;

const klaviyoStore: Module<klaviyoState, never> = {
  namespaced: true,
  state,
  actions: {
    async createProfile(_, params: CreateProfileDto): Promise<KlaviyoProfile> {
      return await klaviyoApi.createProfile(params);
    },
    async verifyProfile(_, params: VerifyProfileDto): Promise<VerifyProfileResponseType> {
      return await klaviyoApi.verifyProfile(params);
    },
    async createProfileAndSendVerificationSms(_, params: CreateProfileDto): Promise<KlaviyoProfile> {
      return await klaviyoApi.createProfileAndSendVerificationSms(params);
    },
    async getProfileById(_, id: string): Promise<KlaviyoProfile> {
      return await klaviyoApi.getProfileById(id);
    },
    async createEvent(_, params: CreateEventDto): Promise<HttpResponseType> {
      return await klaviyoApi.createEvent(params);
    },
    async findProfileByQuery(): Promise<KlaviyoProfile[]> {
      return await klaviyoApi.findProfileByQuery();
    },
    async validatePhoneNumber(_, params: ValidatePhoneNumberDto): Promise<HttpResponseType> {
      return await klaviyoApi.validatePhoneNumber(params);
    },
    async resendVerificationCode(_, params: ResendCodeDto): Promise<HttpResponseType> {
      return await klaviyoApi.resendVerificationCode(params);
    }
  },
};

export default klaviyoStore;
