import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import estateApi from "@/services/api/estateApi";
import { CreateEstate } from "@/types/CreateEstate";
import { CreateEstateMapInterface } from "@/types/CreateEstateMapInterface";

const state = reactive({});

export type estateState = typeof state;

const estateStore: Module<estateState, never> = {
  namespaced: true,
  state,
  actions: {
    getEstates(_, params = {}): Promise<AxiosResponse> {
      return estateApi.getEstates(params);
    },
    getEstate(_, id: string): Promise<AxiosResponse> {
      return estateApi.getEstate(id);
    },
    getSalesPersonEstates() {
      return estateApi.getSalesPersonEstates();
    },
    createEstate(_, data: CreateEstate): Promise<AxiosResponse> {
      return estateApi.createEstate(data);
    },
    updateEstate(
      _,
      payload: { id: string; data: CreateEstate }
    ): Promise<AxiosResponse> {
      return estateApi.updateEstate(payload.id, payload.data);
    },
    deleteEstate(_, id: string): Promise<AxiosResponse> {
      return estateApi.deleteEstate(id);
    },
    getEstateFolder(
      _,
      data: { estateId: string; foldername: string }
    ): Promise<AxiosResponse> {
      return estateApi.getEstateFolder(data.estateId, data.foldername);
    },
    getEstateFile(
      _,
      data: { id: string; filename: string }
    ): Promise<AxiosResponse> {
      return estateApi.getEstateFile(data.id, data.filename);
    },
    createEstateFolder(
      _,
      data: { estateId: string; foldername: string }
    ): Promise<AxiosResponse> {
      return estateApi.createEstateFolder(data);
    },
    shareDocument(
      _,
      data: {
        lotIds: string[];
        fileData: any;
      }
    ): Promise<AxiosResponse> {
      return estateApi.shareDocument(data);
    },
    uploadEstateFile(_, payload) {
      return estateApi.uploadEstateFile(payload);
    },
    uploadEstateLogo(_, payload): Promise<AxiosResponse> {
      return estateApi.uploadEstateLogo(payload);
    },
    renameEstateFolder(
      _,
      data: {
        oldName: string;
        newName: string;
        id: string;
      }
    ): Promise<AxiosResponse> {
      return estateApi.renameEstateFolder(data);
    },
    deleteEstateFolder(
      _,
      data: {
        foldername: string;
        filename: string;
        estateId: string;
      }
    ): Promise<AxiosResponse> {
      return estateApi.deleteEstateFolder(data);
    },
    getEstateMap(_, id: string): Promise<AxiosResponse> {
      return estateApi.getEstateMap(id);
    },
    createEstateMap(_, data: CreateEstateMapInterface): Promise<AxiosResponse> {
      return estateApi.createEstateMap(data);
    },
    updateEstateMap(
      _,
      payload: { id: string; data: Partial<CreateEstateMapInterface> }
    ): Promise<AxiosResponse> {
      return estateApi.updateEstateMap(payload.id, payload.data);
    },
    uploadEstateMapImg(
      _,
      payload: { landownerId: string; estateId: string; data: FormData }
    ): Promise<AxiosResponse> {
      return estateApi.uploadEstateMapImg(
        payload.landownerId,
        payload.estateId,
        payload.data
      );
    },
    deleteEstateMap(_, id: string): Promise<AxiosResponse> {
      return estateApi.deleteEstateMap(id);
    },
    sendContactEmail(_, payload): Promise<AxiosResponse> {
      return estateApi.sendContactEmail(payload);
    },
    sendEstateMap(
      _,
      payload: {
        email: string;
        estateId: string;
        estateTitle: string;
      }
    ): Promise<AxiosResponse> {
      return estateApi.sendEstateMap(payload);
    },
    updateAgentId(_, payload: { agentId: string; id: string }) {
      return estateApi.updateAgentId(payload);
    },
  },
};

export default estateStore;
