import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";

export default {
  getInvoicesByCustomer(
    payload = {} as { customerId: string; estateId: string; }
  ): Promise<AxiosResponse> {
    return axiosClient.get('/invoices/eoi-activity/by-customer', {
      params: {
        customerId: payload.customerId,
        estateId: payload.estateId
      }
    });
  },
};
