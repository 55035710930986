export interface FacadeInterface {
  _id: string;
  name: string;
  price: number;
  storeys: number;
  url: string;
  builderId: string;
}

export type CreateFacadeInterface = Omit<FacadeInterface, '_id'>;

export type QueryFacadeInterface = Partial<FacadeInterface>;

export type UpdateFacadeInterface = Partial<FacadeInterface>;

export const facadeDefault : QueryFacadeInterface = {
  _id: '',
  builderId: '',
  name: '',
  price: undefined,
  storeys: undefined,
  url: '',
}
