import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import lotApi from "@/services/api/lotApi";
import { DocumentTypeEnum } from "@/enums/DocumentTypeEnum";
import { removeEmptyEntries } from "@/helpers/removeEmptyEntries";
import { CreateLotMapInterface } from "@/types/CreateLotMapInterface";
import { LotStatus } from "@/enums/LotStatus";
import { LotOfflineUpdateInterface } from "@/types/LotOfflineUpdateInterface";

const state = reactive({});

export type lotState = typeof state;

const lotStore: Module<lotState, never> = {
  namespaced: true,
  state,
  actions: {
    getLots(_, params = {}): Promise<AxiosResponse> {
      return lotApi.getLots(removeEmptyEntries(params));
    },
    getEstateLots(
      _,
      params: { estateId: string; params?: any }
    ): Promise<AxiosResponse> {
      return lotApi.getEstateLots(params);
    },
    getStagesLots(_, params = {}): Promise<AxiosResponse> {
      return lotApi.getStagesLots(params);
    },
    getLot(_, id: string): Promise<AxiosResponse> {
      return lotApi.getLot(id);
    },
    getOpenLot(_, id: string): Promise<AxiosResponse> {
      return lotApi.getOpenLot(id);
    },
    createLot(_, data: any): Promise<AxiosResponse> {
      return lotApi.createLot(data);
    },
    bulkLotUpload(
      _,
      payload: { stageId: string; data: FormData }
    ): Promise<AxiosResponse> {
      return lotApi.bulkLotUpload(payload);
    },
    updateLot(_, payload: { id: string; data: any }): Promise<AxiosResponse> {
      return lotApi.updateLot(payload.id, payload.data);
    },
    changeLotStatus(
      _,
      payload: { id: string; data: { status: LotStatus } }
    ): Promise<AxiosResponse> {
      return lotApi.changeLotStatus(payload.id, payload.data);
    },
    updateOffline(
      _,
      payload: { id: string; data: LotOfflineUpdateInterface }
    ): Promise<AxiosResponse> {
      return lotApi.updateOffline(payload.id, payload.data);
    },
    deleteLots(_, data = {}): Promise<AxiosResponse> {
      return lotApi.deleteLots(data);
    },
    getLotDocuments(
      _,
      params: {
        id: string;
        documentType: DocumentTypeEnum;
      }
    ): Promise<AxiosResponse> {
      return lotApi.getLotDocuments(params);
    },
    downloadLotDocument(
      _,
      data: { id: string; filename: string; documentType: DocumentTypeEnum }
    ): Promise<AxiosResponse> {
      return lotApi.downloadLotDocument(data);
    },
    deleteLotDocument(
      _,
      data: { lotId: string; fileName: string; documentType: DocumentTypeEnum }
    ): Promise<AxiosResponse> {
      return lotApi.deleteLotDocument(data);
    },
    uploadLotDocuments(_, data: FormData): Promise<AxiosResponse> {
      return lotApi.uploadLotDocument(data);
    },
    updateLotStatusOption(_, data = {}): Promise<AxiosResponse> {
      return lotApi.updateLotStatusOption(data);
    },
    cancelLotOption(_, lotId: string): Promise<AxiosResponse> {
      return lotApi.cancelLotOption(lotId);
    },
    cancelLotOffline(_, lotId: string): Promise<AxiosResponse> {
      return lotApi.cancelLotOffline(lotId);
    },
    setLotPreviousStatus(_, lotId: string): Promise<AxiosResponse> {
      return lotApi.setLotPreviousStatus(lotId);
    },
    setStatusAvailable(_, data = {}): Promise<AxiosResponse> {
      return lotApi.setStatusAvailable(data);
    },
    setStatusNotReleased(_, data = {}): Promise<AxiosResponse> {
      return lotApi.setStatusNotReleased(data);
    },
    getLotsMap(_, params = {}): Promise<AxiosResponse> {
      return lotApi.getLotsMap(params);
    },
    getLotMap(_, id: string): Promise<AxiosResponse> {
      return lotApi.getLotMap(id);
    },
    getLotMapForBuilder(_, id: string): Promise<AxiosResponse> {
      return lotApi.getLotMapForBuilder(id);
    },
    createLotMap(_, data: CreateLotMapInterface): Promise<AxiosResponse> {
      return lotApi.createLotMap(data);
    },
    updateLotMap(
      _,
      payload: { id: string; data: Partial<CreateLotMapInterface> }
    ): Promise<AxiosResponse> {
      return lotApi.updateLotMap(payload.id, payload.data);
    },
    deleteLotMap(_, id: string): Promise<AxiosResponse> {
      return lotApi.deleteLotMap(id);
    },
    getLotActions(_, id: string): Promise<AxiosResponse> {
      return lotApi.getLotActions(id);
    },
    getLotActionsByEstate(_, estateId: string): Promise<AxiosResponse> {
      return lotApi.getLotActionsByEstate(estateId);
    },
    getDashboardStatuses(_, id: string): Promise<AxiosResponse> {
      return lotApi.getDashboardStatuses(id);
    },
    getDashboardLotsByMonth(_, id: string): Promise<AxiosResponse> {
      return lotApi.getDashboardLotsByMonth(id);
    },
    downloadXlsxTemplate(): Promise<AxiosResponse> {
      return lotApi.downloadXlsxTemplate();
    },
    uploadXlsxTemplate(_, data: FormData): Promise<AxiosResponse> {
      return lotApi.uploadXlsxTemplate(data);
    },
    deleteXlsxTemplate(): Promise<AxiosResponse> {
      return lotApi.deleteXlsxTemplate();
    },
    getDahboardUpcoming(_, id: string): Promise<AxiosResponse> {
      return lotApi.getDahboardUpcoming(id);
    },
    setRegistrationDate(_, data = {}): Promise<AxiosResponse> {
      return lotApi.setRegistrationDate(data);
    },
    addLotComment(
      _,
      data: { lotId: string; text: string, estateId: string }
    ): Promise<AxiosResponse> {
      return lotApi.addLotComment(data);
    },
    getLotComments(_, data: { lotId: string, estateId: string }): Promise<AxiosResponse> {
      return lotApi.getLotComments(data);
    },
    updateLotComment(
      _,
      data: {
        commentId: string;
        text: string;
        estateId: string;
      }
    ): Promise<AxiosResponse> {
      return lotApi.updateLotComment(data);
    },
    deleteLotComment(_, data: { commentId: string, estateId: string, lotId: string }): Promise<AxiosResponse> {
      return lotApi.deleteLotComment(data);
    },
    getLotsList(_, data: { estateId: string, isCustomer: boolean, soldOutStatus?: boolean }): Promise<AxiosResponse> {
      return lotApi.getLotsList(data);
    }
  },
};

export default lotStore;
