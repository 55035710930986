import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43d9c9cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "main-layout-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["HeaderComponent"], {
      modelValue: $setup.isActiveBurger,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isActiveBurger) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["SidebarComponent"], {
        navigation: $setup.navList,
        isActiveBurger: $setup.isActiveBurger
      }, null, 8, ["navigation", "isActiveBurger"]),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              _createElementVNode("main", {
                class: _normalizeClass(["wrapper-main-layout-content", { 'no-margin-left': $setup.isReportsRoute }]),
                style: _normalizeStyle($props.wrapperContentStyles)
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { isActiveBurger: $setup.isActiveBurger }, null, 8, ["isActiveBurger"]))
              ], 6)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}