import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a71a6aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sidebar"
}
const _hoisted_2 = {
  key: 0,
  class: "sidebar-title"
}
const _hoisted_3 = {
  key: 1,
  class: "wrapper-logo"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "navigation-link-title" }
const _hoisted_7 = {
  key: 0,
  class: "navigation-link-counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper-sidebar", { 'wrapper-sidebar-open': !$props.isActiveBurger, 'no-background': $setup.isReportsRoute }])
  }, [
    _createVNode($setup["SidebarShortPanel"]),
    (!$setup.isReportsRoute)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($setup.navTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.navTitle), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                ($setup.selectedEstate)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: $setup.selectedEstate.logoUrl
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])),
          _createElementVNode("nav", null, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getNormalizedNavigation, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(["navigation-link", [$setup.getActiveRouteClass(item), { 'unactive-link ': item.soon }]]),
                    to: item.link,
                    onClick: ($event: any) => ($setup.onNavigationLink(item))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: require(`../assets/icons/${item.icon}`),
                        class: _normalizeClass(item.class)
                      }, null, 10, _hoisted_5),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1),
                      (item.counter)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.counter), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["to", "class", "onClick"])
                ]))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}