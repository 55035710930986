import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import reiApi from "@/services/api/reiApi";
import { StatesEnum } from "@/enums/StatesEnum";

const state = reactive({
  userData: {
    accessToken: "",
    state: "",
  },
});

export type reiState = typeof state;

const reiStore: Module<reiState, never> = {
  namespaced: true,
  state,
  mutations: {
    setUserData(states: reiState, payload: { accessToken: string }): void {
      if (!payload) {
        return;
      }
      states.userData = {
        accessToken: payload.accessToken,
        state: StatesEnum.QLD,
      };
    },
  },
  actions: {
    getTemplates(): Promise<AxiosResponse> {
      return reiApi.getTemplates();
    },
    createForm(_, data): Promise<AxiosResponse> {
      return reiApi.createForm(data);
    },
  },
};

export default reiStore;
