import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { DocumentTypeEnum } from "@/enums/DocumentTypeEnum";
import { CreateLotMapInterface } from "@/types/CreateLotMapInterface";
import { LotStatus } from "@/enums/LotStatus";
import { removeEmptyEntries } from "@/helpers/removeEmptyEntries";
import { LotOfflineUpdateInterface } from "@/types/LotOfflineUpdateInterface";

export default {
  getLots(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("lots", { params });
  },
  getEstateLots(data: {
    estateId: string;
    params?: any;
  }): Promise<AxiosResponse> {
    return axiosClient.get(`lots/basic-lot-data/${data.estateId}`, {
      params: removeEmptyEntries(data.params),
    });
  },
  getLot(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`lots/${id}`);
  },
  getOpenLot(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`lots/open/${id}`);
  },
  getStagesLots(params = {}): Promise<AxiosResponse> {
    return axiosClient.get(`lots/by-stage-ids`, { params });
  },
  createLot(data: any): Promise<AxiosResponse> {
    return axiosClient.post("lots", data);
  },
  bulkLotUpload(payload: {
    stageId: string;
    data: FormData;
  }): Promise<AxiosResponse> {
    return axiosClient.post(
      `lots/bulk/upload/${payload.stageId}`,
      payload.data
    );
  },
  updateLot(id: string, data: any): Promise<AxiosResponse> {
    return axiosClient.patch(`lots/${id}`, data);
  },
  changeLotStatus(
    id: string,
    data: { status: LotStatus }
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`lots/change-status/${id}`, data);
  },
  updateOffline(
    id: string,
    data: LotOfflineUpdateInterface
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`lots/offline-sale/${id}`, data);
  },
  deleteLots(data = {}): Promise<AxiosResponse> {
    return axiosClient.post("lots/bulk-delete", data);
  },
  getLotDocuments(params: {
    id: string;
    documentType: DocumentTypeEnum;
  }): Promise<AxiosResponse> {
    return axiosClient.get("lots/documents/get", { params });
  },
  downloadLotDocument(params: {
    id: string;
    documentType: DocumentTypeEnum;
    filename: string;
  }): Promise<AxiosResponse> {
    return axiosClient.get("lots/download/file", { params });
  },
  deleteLotDocument(params: {
    lotId: string;
    documentType: DocumentTypeEnum;
    fileName: string;
  }): Promise<AxiosResponse> {
    return axiosClient.delete("lots/documents/delete", { params });
  },
  uploadLotDocument(data: FormData): Promise<AxiosResponse> {
    return axiosClient.post(`lots/documents/upload`, data);
  },
  updateLotStatusOption(data = {}): Promise<AxiosResponse> {
    return axiosClient.patch("lots/status/option", data);
  },
  cancelLotOption(lotId: string): Promise<AxiosResponse> {
    return axiosClient.patch(`lots/cancel-option/${lotId}`);
  },
  cancelLotOffline(lotId: string): Promise<AxiosResponse> {
    return axiosClient.patch(`lots/cancel-offline-sale/${lotId}`);
  },
  setLotPreviousStatus(lotId: string): Promise<AxiosResponse> {
    return axiosClient.get(`lots/status/return-previous/${lotId}`);
  },
  setStatusAvailable(data = {}): Promise<AxiosResponse> {
    return axiosClient.patch("lots/status/available", data);
  },
  setStatusNotReleased(data = {}): Promise<AxiosResponse> {
    return axiosClient.patch("lots/status/not-released", data);
  },
  getLotsMap(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("maps/lots", { params });
  },
  getLotMap(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`maps/lots/get-by-lot-id/${id}`);
  },
  getLotMapForBuilder(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`maps/lots/get-by-lot-id-for-builder/${id}`);
  },
  createLotMap(data: CreateLotMapInterface): Promise<AxiosResponse> {
    return axiosClient.post("maps/lots", data);
  },
  updateLotMap(
    id: string,
    data: Partial<CreateLotMapInterface>
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`maps/lots/${id}`, data);
  },
  deleteLotMap(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`maps/lots/${id}`);
  },
  getLotActions(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`lot-notations/lot/${id}`);
  },
  getLotActionsByEstate(estateId: string): Promise<AxiosResponse> {
    return axiosClient.get(`lot-notations/estate/${estateId}`);
  },
  getDashboardStatuses(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`lots/dashboard/lots-by-status/${id}`);
  },
  getDashboardLotsByMonth(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`lots/dashboard/lots-revenue-by-month/${id}`);
  },
  downloadXlsxTemplate(): Promise<AxiosResponse> {
    return axiosClient.get("aws/example-xlsx/download");
  },
  uploadXlsxTemplate(data: FormData): Promise<AxiosResponse> {
    return axiosClient.post("aws/example-xlsx/upload", data);
  },
  deleteXlsxTemplate(): Promise<AxiosResponse> {
    return axiosClient.delete("aws/example-xlsx/delete");
  },
  getDahboardUpcoming(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`lots/dashboard/lots-upcoming-actions/${id}`);
  },
  setRegistrationDate(data = {}): Promise<AxiosResponse> {
    return axiosClient.patch("lots/bulk/registration-date", data);
  },
  addLotComment(data: { lotId: string, text: string, estateId: string }): Promise<AxiosResponse> {
    return axiosClient.post("lot-comments", data);
  },
  getLotComments(data: { lotId: string, estateId: string }): Promise<AxiosResponse> {
    return axiosClient.get("lot-comments", {
      params: {
        lotId: data.lotId,
        estateId: data.estateId
      }
    });
  },
  updateLotComment(data: {
    commentId: string;
    text: string;
    estateId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`lot-comments/${data.commentId}`, {
      text: data.text,
      estateId: data.estateId
    });
  },
  deleteLotComment(data: { commentId: string, lotId: string, estateId: string }): Promise<AxiosResponse> {
    return axiosClient.delete(`lot-comments/${data.commentId}`, {
      params: {
        lotId: data.lotId,
        estateId: data.estateId
      }
    });
  },
  getLotsList(data: { estateId: string, isCustomer: boolean, soldOutStatus?: boolean}): Promise<AxiosResponse> {
    return axiosClient.get("maps/lots/lot-list/by-estate", {
      params: {
        estateId: data.estateId,
        isCustomer: data.isCustomer,
        soldOutStatus: data.soldOutStatus,
      }
    });
  }
};
