enum InitialHoldStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  DECLINED = "Declined",
  EXPIRED = "Expired",
  CANCELLED = "Cancelled",
  CLOSED = "Progressed",
}

export default InitialHoldStatus;
