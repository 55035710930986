import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import appSettingsApi from "@/services/api/appSettingsApi";

const state = reactive({});

export type appSettingsState = typeof state;

const appSettingsStore: Module<appSettingsState, never> = {
  namespaced: true,
  state,
  actions: {
    getAppSettings(): Promise<AxiosResponse> {
      return appSettingsApi.getAppSettings();
    },
  },
};

export default appSettingsStore;
