export enum BuyerState {
  NSW,
  QLD,
  SA,
  VIC,
  NT,
  WA,
  ACT,
  TAS,
}
