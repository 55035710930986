import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";

export default {
  getTemplates(): Promise<AxiosResponse> {
    return axiosClient.get("docusign/templates");
  },
  createEnvelope(
    payload = {} as {
      templateId: string;
      eoiId: string;
      additionalFields: { [key: string]: string };
    }
  ): Promise<AxiosResponse> {
    return axiosClient.post("docusign/contract", {
      templateId: payload.templateId,
      eoiId: payload.eoiId,
      additionalFields: payload.additionalFields,
    });
  },
  login(
    payload = {} as { accountId: string; token: string; baseUrl: string }
  ): Promise<AxiosResponse> {
    return axiosClient.get("docusign");
  },
  validateTemplate(payload: { templateId: string }): Promise<AxiosResponse> {
    return axiosClient.post(
      `docusign/templates/validate/${payload.templateId}/1`
    );
  },
  getUserUrl(payload: { accessToken: string }): Promise<AxiosResponse> {
    return axiosClient.post("docusign/get-user-url", payload);
  },
  sendRequestAccessNotification(estateId: string): Promise<AxiosResponse> {
    return axiosClient.post(
      `docusign/send-email/request-access/estate/${estateId}`
    );
  },
  getUserData(payload: {
    accessToken: string;
    expiresIn: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post(`docusign/get-user-data`, payload);
  },
};
