import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from "vue-router";
import routes from "@/router/routes";
import store from "@/store";
import { UserRoles } from "@/enums/UserRoles";
import LocalStorageService from "@/services/localStorage.service";
import { trackRouter } from "vue-gtag-next";
import { ROUTER_SCROLL_BEHAVIORS } from "@/constants/scrollBehaviors";
const customerRoutes = [
  "EstateMap",
  "EOIRequest",
  "EOIRequestPayment",
  "EOIWaitlist",
  "Builder",
  "RequestQuote",
];
let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta?.customScroll) return;
    if (to.meta.scrollBehavior === ROUTER_SCROLL_BEHAVIORS.INSTANT) {
      return { top: 0 };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (customerRoutes.includes(to.name as string)) {
    changeFavIconToDark();
    changeTabTitle(to);
  } else {
    changeIconAndTitleToDefault();
  }
  if (process.env.VUE_APP_IS_IN_MAINTENANCE === "true" && to.name !== "Maintenance") {
    next({ name: "Maintenance" });
  } else {
    const isAuthRoute = to?.meta.access;
    const isLoginRoute = to?.meta.isLogin;
    if (store.state.user.accessToken) {
      if (store.state.user.redirectLink) {
        const link = store.state.user.redirectLink;
        store.commit("user/clearRedirectLink");
        return next(link);
      }
      if (isLoginRoute) {
        return next(toDefaultPage());
      }
      if (
        !to.meta.access ||
        (to.meta.access as string[]).includes(store.state.user.data.role)
      ) {
        return next();
      } else {
        return next(toDefaultPage());
      }
    }
    if (isAuthRoute) {
      setRedirectLinkAndMoveOn(from, to, next);
    }
    if (!to.params.unauthorized) {
      store.commit("user/clearRedirectLink");
    }
    cleanEoiUserData(from, to);
    next();
  }
});

trackRouter(router);

function cleanEoiUserData(from: RouteLocationNormalized, to: RouteLocationNormalized) {
  if (from.name === "EOIRequest" && to.name !== "EOIRequestPayment") {
    LocalStorageService.remove("eoiUserData");
  }
}

function toDefaultPage(): RouteLocationRaw {
  if (
    store.state.user.data.role === UserRoles.LANDOWNER ||
    store.state.user.data.role === UserRoles.SALES_PERSON
  ) {
    return { name: "Dashboard" };
  }
  if (store.state.user.data.role === UserRoles.SUPER_ADMIN) {
    return { name: "AdminUsers" };
  }
  if (store.state.user.data.role === UserRoles.CUSTOMER) {
    return { name: "CustomerLots" };
  }
  store.dispatch("user/logout");
  return { path: "/" };
}

function changeFavIconToDark() {
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = "/favicon-dark.ico";
}

function changeTabTitle(to: RouteLocationNormalized) {
  if (to.params.estateId) {
    store.dispatch("estate/getEstate", to.params.estateId).then((res) => {
      document.title = res.data.title;
    });
  }
}

function changeIconAndTitleToDefault() {
  link.href = "/favicon.ico";
  document.title = "LotTrackr";
}

function setRedirectLinkAndMoveOn(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  store.commit("user/setRedirectLink", to);
  if (from.name === "EstateMap") {
    return next({
      name: "EstateMap",
      params: { estateId: from.params.estateId, unauthorized: "true" },
      query: from.query,
    });
  }
  if (to.name === "EOIWaitlist") {
    return next({
      name: "EstateMap",
      params: { estateId: to.params.estateId, unauthorized: "true" },
    });
  }
  return next({ name: "Landing", params: { unauthorized: "true" } });
}

export default router;
