import { reactive } from "vue";
import { Module } from "vuex";
import { CreateFacadeInterface, facadeDefault, FacadeInterface, QueryFacadeInterface, UpdateFacadeInterface } from "@/types/FacadeInterface";
import facadeApi from "@/services/api/facadeApi";

const state = reactive({
  ...facadeDefault,
  selectedFacadeId: null as string | null
});

export type facadeState = typeof state;

const facadeStore: Module<facadeState, never> = {
  namespaced: true,
  state,
  mutations: {
    updateSelectedFacadeId(state, facadeId: string) {
      state.selectedFacadeId = facadeId;
    }
  },
  getters: {
    selectedFacadeId: state => state.selectedFacadeId
  },
  actions: {
    createFacade(_, params: CreateFacadeInterface): Promise<FacadeInterface> {
      return facadeApi.createFacade(params);
    },
    uploadImage(_, params: { builderId: string, image: FormData}) {
      return facadeApi.uploadImageFacade(params.builderId, params.image);
    },
    getFacadesByQuery(_, params: QueryFacadeInterface) {
      return facadeApi.getFacadesByQuery(params);
    },
    deleteFacade(_, params: { id: string }) {
      return facadeApi.deleteFacade(params.id);
    },
    updateFacade(_, params: { dto: UpdateFacadeInterface, id: string}) {
      return facadeApi.updateFacade(params.dto, params.id);
    },
  },
};

export default facadeStore;
