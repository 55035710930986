import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { CreateEstate } from "@/types/CreateEstate";
import { CreateEstateMapInterface } from "@/types/CreateEstateMapInterface";

export default {
  getEstates(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("estates", { params });
  },
  getEstate(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`estates/${id}`);
  },
  getSalesPersonEstates() {
    return axiosClient.get("sales-person/estates");
  },
  createEstate(data: CreateEstate): Promise<AxiosResponse> {
    return axiosClient.post("estates", data);
  },
  updateEstate(
    id: string,
    data: Partial<CreateEstate>
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`estates/${id}`, data);
  },
  deleteEstate(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`estates/${id}`);
  },
  getEstateFolder(estateId: string, foldername: string): Promise<AxiosResponse> {
    return axiosClient.get("estates/documents/get/folder", {
      params: { estateId, foldername },
    });
  },
  getEstateFile(id: string, filename: string): Promise<AxiosResponse> {
    return axiosClient.get("estates/download/file", {
      params: { id, filename },
    });
  },
  createEstateFolder(data: {
    estateId: string;
    foldername: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("estates/documents/folder", data);
  },
  shareDocument(data: {
    lotIds: string[];
    fileData: any;
  }): Promise<AxiosResponse> {
    return axiosClient.post("estates/documents/share", data);
  },
  uploadEstateFile(data: {
    estateId: string;
    file: FormData;
  }): Promise<AxiosResponse> {
    return axiosClient.post(`estates/upload/file/${data.estateId}`, data.file);
  },
  uploadEstateLogo(data: {
    estateId: string;
    file: FormData;
  }): Promise<AxiosResponse> {
    return axiosClient.post(`estates/logo/upload/${data.estateId}`, data.file);
  },
  renameEstateFolder(params: {
    oldName: string;
    newName: string;
    id: string;
  }): Promise<AxiosResponse> {
    return axiosClient.put("estates/documents/rename", {}, { params });
  },
  deleteEstateFolder(params: {
    foldername: string;
    filename: string;
    estateId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.delete("estates/documents/delete", { params });
  },
  getEstateMap(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`maps/estates/get-by-estate-id/${id}`);
  },
  createEstateMap(data: CreateEstateMapInterface): Promise<AxiosResponse> {
    return axiosClient.post("maps/estates", data);
  },
  updateEstateMap(
    id: string,
    data: Partial<CreateEstateMapInterface>
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`maps/estates/${id}`, data);
  },
  uploadEstateMapImg(
    landownerId: string,
    estateId: string,
    data: FormData
  ): Promise<AxiosResponse> {
    return axiosClient.post(
      `maps/estates/image/${landownerId}/${estateId}`,
      data
    );
  },
  deleteEstateMap(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`maps/estates/${id}`);
  },
  sendContactEmail(payload: {
    estateId: string;
    data: Record<string, string>;
  }): Promise<AxiosResponse> {
    const { estateId, data } = payload;
    return axiosClient.post(`estates/send/contact-email/${estateId}`, data);
  },
  sendEstateMap(data: {
    email: string;
    estateId: string;
    estateTitle: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("estates/send/estate-map", data);
  },
  unassignLawyer(estateId: string) {
    return axiosClient.get(`estates/lawyer/unassigned/${estateId}`);
  },
  lawyerInvitation(lawyerId: string) {
    return axiosClient.get(`estates/lawyer/invitation/${lawyerId}`);
  },
  checkLawyerConnection(lawyerId: string) {
    return axiosClient.get(`zepto/lawyer/check/${lawyerId}`);
  },
  changeEstateActive({
    estateId,
    isActive,
  }: {
    estateId: string;
    isActive: boolean;
  }) {
    return axiosClient.patch("estates/toggle/active", {
      estateId,
      isActive,
    });
  },
  updateAgentId({ agentId, id }: { agentId: string; id: string }) {
    return axiosClient.patch(`estates/update-agentId/${id}`, { agentId });
  },
};
