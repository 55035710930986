export const toKebabCase = (string: string): string => {
  return string.replace(/\s+/g, "-").toLowerCase();
};
export const getFirstLetter = (string: string, isLower = false): string => {
  return isLower
    ? string.charAt(0).toLowerCase()
    : string.charAt(0).toUpperCase();
};
export const fromSpacedToCamelCase = (label: string) => {
  return label
    .split(" ")
    .flatMap((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};
export const capitalizeString = (inputString: string) => {
  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  );
};

export const replaceUnderscoreWithSpaces = (text: string) => {
  return text.replace(/_/g, ' ');
};

export const compareStrings = (a: string, b: string): number => {
  const numA = parseInt(a.match(/^\d+/)?.[0] || '0');
  const numB = parseInt(b.match(/^\d+/)?.[0] || '0');
  return numA - numB;
}
