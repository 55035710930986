export const enum LandownerNavigationSlugs {
  DASHBOARD = "dashboard",
  LOTS = "lots",
  DOCUMENTS = "documents",
  INITIAL_HOLD = "initial-hold",
  EOI = "eoi",
  WAITLIST = "waitlist",
  LEADS = "leads",
  CONTACTS = "contacts",
  LISTING = "listing",
}
