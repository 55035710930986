import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { removeEmptyEntries } from "@/helpers/removeEmptyEntries";
import { LeadStatus } from "@/enums/LeadStatus";

export default {
  getContacts(
    payload = {} as { id: string; buyerStatus: string; searchKey: string }
  ): Promise<AxiosResponse> {
    const params = removeEmptyEntries({
      buyerStatus: payload.buyerStatus,
      searchKey: payload.searchKey,
    });
    return axiosClient.get(`users/contacts/${payload.id}`, { params });
  },
  getLeads(
    payload = {} as { estateId: string; query: string; status: LeadStatus }
  ): Promise<AxiosResponse> {
    const params = removeEmptyEntries({
      query: payload.query,
      status: payload.status,
    });
    return axiosClient.get(`leads/elastic-search/${payload.estateId}`, {
      params,
    });
  },
  getLead(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`leads/${id}`);
  },
  createLead(data: any): Promise<AxiosResponse> {
    return axiosClient.post("leads", data);
  },
  updateLead(data: {
    id: string;
    data: Record<string, string>;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`leads/${data.id}`, data.data);
  },
  bulkDeleteLeads(data: {
    estateId: string;
    leadIds: string[];
  }): Promise<AxiosResponse> {
    return axiosClient.patch("leads/bulk/delete", data);
  },
  bulkChangeLeadsStatuses(data: {
    estateId: string;
    leadIds: string[];
    status: LeadStatus;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("leads/bulk/change-status", data);
  },
  shareLeadsLinks(data: {
    leadIds: string[];
    lotIds: string[];
  }): Promise<AxiosResponse> {
    return axiosClient.post("leads/share-links", data);
  },
  shareEoiLinkWithContacts(data: {
    contactId: string;
    lotId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("users/share-eoi-link-with-contacts", data);
  },
  importLeads(data: {
    estateId: string;
    data: FormData;
  }): Promise<AxiosResponse> {
    return axiosClient.post(`leads/bulk/upload/${data.estateId}`, data.data);
  },
  downloadLeadsTemplate(): Promise<AxiosResponse> {
    return axiosClient.get("aws/example-xlsx/leads/download");
  },
  uploadLeadsTemplate(data: FormData): Promise<AxiosResponse> {
    return axiosClient.post("aws/example-xlsx/leads/upload", data);
  },
  deleteLeadsTemplate(): Promise<AxiosResponse> {
    return axiosClient.delete("aws/example-xlsx/leads/delete");
  },
  getEnquiries(data: {
    estateId: string;
    leadId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.get("received-messages", {
      params: {
        estateId: data.estateId,
        leadId: data.leadId,
      },
    });
  },
  getUnwatchedLeads(estateId: string): Promise<AxiosResponse> {
    return axiosClient.get(`leads/count/unwatched/${estateId}`);
  },
  watchedLead(payload = {}): Promise<AxiosResponse> {
    return axiosClient.patch("leads/bulk/watch-lead/", payload);
  },
  sendReservationLinkToNewLead(payload = {}): Promise<AxiosResponse> {
    return axiosClient.post("leads/send/reservation-link-to-new-lead", payload);
  },
};
