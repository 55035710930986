import { reactive } from 'vue';
import userApi from '@/services/api/userApi';
import { AxiosResponse } from 'axios';
import { Module } from 'vuex';
import { ContactEmail } from '@/types/ContactEmail';
import { CreateUser } from '@/types/CreateUser';
import {
  LandownerDetailsInterface,
  UserInterface,
} from '@/types/UserInterface';
import LocalStorageService from '@/services/localStorage.service';
import { RouteLocationNormalized } from 'vue-router';
import { EstateInterface } from '@/types/EstateInterface';
import { CustomerDetails } from '@/types/CustomerDetails';
import store from '..';
import { AskAQuestionEmail } from '@/types/AskAQuestionEmail';

const state = reactive({
  data: {} as UserInterface,
  accessToken: LocalStorageService.getAccessToken(),
  refreshToken: LocalStorageService.getRefreshToken(),
  rememberMe: false,
  selectedEstateId: '',
  landownerEstates: [] as EstateInterface[],
  redirectLink: '' as string | RouteLocationNormalized,
});

export type userState = typeof state;

const userStore: Module<userState, never> = {
  namespaced: true,
  state,
  getters: {
    getAgentId(states: userState) {
      const selectedEstate = states.landownerEstates.find(
        (estate) => estate._id === store.state.user.selectedEstateId
      );
      return selectedEstate?.agentId;
    },
  },
  mutations: {
    setTokens(
      state: userState,
      payload: {
        accessToken: string;
        refreshToken?: string;
        rememberMe?: boolean;
      }
    ): void {
      state.refreshToken = payload.refreshToken || null;
      state.accessToken = payload.accessToken;
      if (typeof payload.rememberMe === 'boolean') {
        state.rememberMe = payload.rememberMe;
      }
      localStorage.setItem('accessToken', payload.accessToken);
      if (
        (state.rememberMe || localStorage.getItem('refreshToken')) &&
        payload.refreshToken
      ) {
        localStorage.setItem('refreshToken', payload.refreshToken);
      }
    },
    setUserData(state: userState, payload: any): void {
      state.data = payload;
    },
    clear(state: userState): void {
      state.data = {} as UserInterface;
      state.rememberMe = false;
      state.refreshToken = '';
      state.accessToken = '';
      state.selectedEstateId = '';
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('urlAfterVerify');
      localStorage.removeItem('verifyEmail');
    },
    setSelectedEstate(state: userState, payload: string): void {
      state.selectedEstateId = payload;
    },
    setLandownerEstates(state: userState, payload: EstateInterface[]): void {
      state.landownerEstates = payload;
    },
    setRedirectLink(
      state: userState,
      payload: string | RouteLocationNormalized
    ): void {
      state.redirectLink = payload;
    },
    clearRedirectLink(state: userState): void {
      state.redirectLink = '';
    },
    deleteCustomerDocument(state: userState, payload: number): void {
      (state.data.detailsId as CustomerDetails).documents?.splice(payload, 1);
    },
    setCustomerDetails(state: userState, payload: CustomerDetails): void {
      state.data.detailsId = payload;
    },
    setAgentId(states: userState, agentId: string): void {
      const selectedEstate = states.landownerEstates.find(
        (estate) => estate._id === store.state.user.selectedEstateId
      );
      if (selectedEstate) {
        selectedEstate.agentId = agentId;
      }
    },
  },
  actions: {
    login(
      { commit },
      payload: { password: string; login: string; rememberMe: boolean }
    ): Promise<AxiosResponse> {
      return userApi.login(payload).then((response) => {
        const { accessToken, refreshToken, user } = response.data;
        commit('setTokens', {
          accessToken,
          refreshToken,
          rememberMe: payload.rememberMe,
        });
        commit('setUserData', user);
        return response;
      });
    },
    updateRefreshToken(
      { commit },
      refreshTokenPayload: string
    ): Promise<AxiosResponse> {
      return userApi.updateRefreshToken(refreshTokenPayload).then((res) => {
        const accessToken = res.data;
        commit('setTokens', { accessToken });
        return res.data;
      });
    },
    updateTokens({ commit }): Promise<AxiosResponse> {
      return userApi.updateTokens().then((res) => {
        const { accessToken, refreshToken } = res.data;
        commit('setTokens', { accessToken, refreshToken });
        return res.data;
      });
    },
    getMe({ commit }): Promise<AxiosResponse> {
      return userApi.getMe().then((res) => {
        commit('setUserData', res.data);
        return res;
      });
    },
    logout({ state, commit }): Promise<AxiosResponse> {
      const token = state.accessToken || '';
      return userApi.logout(token).finally(() => {
        commit('clear');
      });
    },
    signUp(
      _,
      payload: {
        data: Record<string, string>;
        params: Record<string, string>;
      }
    ): Promise<AxiosResponse> {
      return userApi.signUp(payload);
    },
    verify({ commit }, id: string) {
      return userApi.verify(id).then((response) => {
        const { accessToken, refreshToken, user } = response.data;
        commit('setTokens', {
          accessToken,
          refreshToken,
        });
        commit('setUserData', user);
        return response;
      });
    },
    verifyUnregisteredCustomer(_, id: string) {
      return userApi.verifyUnregisteredCustomer(id);
    },
    retryVerify(_, payload: Record<string, string>) {
      return userApi.retryVerify(payload);
    },
    forgotPassword(_, email: string) {
      return userApi.forgotPassword(email);
    },
    changePassword(_, payload) {
      return userApi.changePassword(payload);
    },
    activatePassword(_, payload) {
      return userApi.activatePassword(payload);
    },
    changePasswordForLoginUser(
      _,
      payload: {
        oldPassword: string;
        newPassword: string;
      }
    ) {
      return userApi.changePasswordForLoginUser(payload);
    },
    contactEmail(_, payload: ContactEmail): Promise<AxiosResponse> {
      return userApi.contactEmail(payload);
    },
    askAQuestionEmail(_, payload: AskAQuestionEmail): Promise<AxiosResponse> {
      return userApi.askAQuestionEmail(payload);
    },
    createUser(_, payload: CreateUser): Promise<AxiosResponse> {
      return userApi.createUser(payload);
    },
    getUsers(_, params = {}): Promise<AxiosResponse> {
      return userApi.getUsers(params);
    },
    getUser(_, id: string): Promise<AxiosResponse> {
      return userApi.getUser(id);
    },
    getUsersSalesPerson(_, payload = {}) {
      return userApi.getUsersSalesPerson(payload);
    },
    updateUser(
      { commit, state },
      payload: { id: string; data: Partial<UserInterface> }
    ): Promise<AxiosResponse> {
      return userApi.updateUser(payload).then((res) => {
        if (payload.id === state.data._id) {
          commit('setUserData', res.data);
        }
        return res;
      });
    },
    updateUserProfile({ commit }, id: string) {
      return userApi.getUser(id).then((res) => {
        commit('setUserData', res.data);
      });
    },
    updateUserEmail(
      _,
      payload: { id: string; data: { email: string } }
    ): Promise<AxiosResponse> {
      return userApi.updateUserEmail(payload);
    },
    updateLandownerDetails(
      _,
      payload: { id: string; data: Partial<LandownerDetailsInterface> }
    ): Promise<AxiosResponse> {
      return userApi.updateLandownerDetails(payload.id, payload.data);
    },
    updateLawyerDetails(
      _,
      payload: { id: string; data: Partial<LandownerDetailsInterface> }
    ): Promise<AxiosResponse> {
      return userApi.updateLawyerDetails(payload.id, payload.data);
    },
    resendActivationEmail(
      _,
      data: { email: string; userId: string }
    ): Promise<AxiosResponse> {
      return userApi.resendActivationEmail(data);
    },
    getStripeConnect(_, params = {}): Promise<AxiosResponse> {
      return userApi.getStripeConnect(params);
    },
    createStripeConnect(
      _,
      data: { landownerId: string }
    ): Promise<AxiosResponse> {
      return userApi.createStripeConnect(data);
    },
    updateStripeConnect(_, id: string): Promise<AxiosResponse> {
      return userApi.updateStripeConnect(id);
    },
    deleteUser({ commit }, userId: string) {
      return userApi.deleteUser(userId).then(() => {
        commit('clear');
      });
    },
    createSalesPerson(_, payload) {
      return userApi.createSalesPerson(payload);
    },
    updateSalesPersonByLandowner(_, payload) {
      return userApi.updateSalesPersonByLandowner(payload.userId, payload.form);
    },
    updateSalesPersonProfile(_, payload) {
      return userApi.updateSalesPersonProfile(payload);
    },
    deleteSalesPerson(_, id: string) {
      return userApi.deleteSalesPerson(id);
    },
    saveSelectedLotExtraColumns(_, data = []): Promise<AxiosResponse> {
      return userApi.saveSelectedLotExtraColumns(data);
    },
    refreshAgentId({ commit }, agentId) {
      commit('setAgentId', agentId);
    },
    getChargebeePortalSession(_): Promise<AxiosResponse> {
      return userApi.getChargebeePortalSession();
    },
    setUserAndTokens({ commit }, payload) {
      commit('setTokens', {
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      });
      commit('setUserData', payload.user);
    },
  },
};

export default userStore;
