const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

export default {
  getAccessToken(): string | null {
    return this.get(ACCESS_TOKEN);
  },
  getRefreshToken(): string | null {
    return this.get(REFRESH_TOKEN);
  },
  setAccessToken(token: string) {
    this.set(ACCESS_TOKEN, token);
  },
  setRefreshToken(token: string) {
    this.set(REFRESH_TOKEN, token);
  },
  removeAccessToken() {
    this.remove(ACCESS_TOKEN);
  },
  removeRefreshToken() {
    this.remove(REFRESH_TOKEN);
  },
  set(name: string, value: string): void {
    localStorage.setItem(name, value);
  },
  get(name: string): string | null {
    return localStorage.getItem(name);
  },
  remove(name: string): void {
    localStorage.removeItem(name);
  },
};
