import { replaceUnderscoreWithSpaces } from "@/helpers/transformation/string";

export interface BorrowCalculatorInterface {
  ClientID: string;
  Token: string;
  TestDomain: string;
  CalcName: string;
  JointIncome: '0' | '1';
  NoOfDependents: '0' | '1' | '2' | '3';
  InterestRate: string;
  LoanTerm: string;
  IncomeType: IncomeType;
  NetSalary1: string;
  NetSalaryFre1: Periodicity;
  NetSalary2: string;
  NetSalaryFre2: Periodicity; 
  NetSalary3: string;
  NetSalaryFre3: Periodicity;
  Expense1: string;   
  Expense2: string;     
  Expense3: string;  
  Expense4: string;   
  Expense1Fre: Periodicity;
  Expense2Fre: Periodicity;
  Expense4Fre: Periodicity;
}

export interface BorrowCalculatorResult {
  LoanAmountString: string | null;
  RepaymentMonthlyString: string | null;
  RepaymentFortnightlyString: string | null;
  RepaymentWeeklyString: string | null;
  LoanBalanceString: string | null;
  TotalPaymentString: string | null;
  TableStr: string | null;
}

export enum BorrowCalculatorFields {
  CLIENT_ID = 'ClientID',
  TOKEN = 'Token',
  TEST_DOMAIN = 'TestDomain',
  CALC_NAME = 'CalcName',
  JOINT_INCOME = 'JointIncome',
  NO_OF_DEPENDENTS = 'NoOfDependents',
  INTEREST_RATE = 'InterestRate',
  LOAN_TERM = 'LoanTerm',
  INCOME_TYPE = 'IncomeType',
  NET_SALARY_1 = 'NetSalary1',
  NET_SALARY_FRE_1 = 'NetSalaryFre1',
  NET_SALARY_2 = 'NetSalary2',
  NET_SALARY_FRE_2 = 'NetSalaryFre2',
  NET_SALARY_3 = 'NetSalary3',
  NET_SALARY_FRE_3 = 'NetSalaryFre3',
  EXPENSE_1 = 'Expense1',
  EXPENSE_2 = 'Expense2',
  EXPENSE_3 = 'Expense3',
  EXPENSE_4 = 'Expense4',
  EXPENSE_1_FRE = 'Expense1Fre',
  EXPENSE_2_FRE = 'Expense2Fre',
  EXPENSE_4_FRE = 'Expense4Fre'
}

export interface BorrowCalculatorInterface {
  [key: string]: string | '0' | '1' | Periodicity;
}

export enum IncomeType {
  NET_INCOME = '1',
  GROSS_INCOME = '0',
}

export enum Periodicity {
  YEARLY = '1',
  HALF_YEARLY = '2',
  MONTHLY = '12',
  FORTNIGHTLY = '26',
}

export enum RepaymentFre {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  FORTNIGHTLY = 'fortnightly', 
}


export interface CalculatorOptions {
  willLiveInHome : boolean;
  isApplicant1Active : boolean;
  hasAdditionalIncome1 : boolean;
  hasAdditionalIncome2 : boolean;
  additionalIncome1 : string;
  additionalIncome2 : string;
  repaymentFre: RepaymentFre;
};

export const calculatorOptionsDefault : CalculatorOptions = {
  willLiveInHome: true,
  isApplicant1Active : true,
  hasAdditionalIncome1: true,
  hasAdditionalIncome2: true,
  additionalIncome1 : '$70,000',
  additionalIncome2 : '$0',
  repaymentFre: RepaymentFre.WEEKLY,
};

export enum CalculatorOptionsFields {
  WILL_LIVE_IN_HOME = 'willLiveInHome',
  IS_APPLICANT_1_ACTIVE = 'isApplicant1Active',
  HAS_ADDITIONAL_INCOME_1 = 'hasAdditionalIncome1',
  HAS_ADDITIONAL_INCOME_2 = 'hasAdditionalIncome2',
  ADDITIONAL_INCOME_1 = 'additionalIncome1',
  ADDITIONAL_INCOME_2 = 'additionalIncome2',
  REPAYMENT_FRE = 'repaymentFre',
};

export interface CalculatorOptions {
  [key: string]: boolean | RepaymentFre | string;
}

export enum SectionTabs {
  ABOUT = 'about',
  INCOME = 'income',
  EXPENSES = 'expenses',
  RESULT = 'result',
};

export const borrowCalculatorDefault : BorrowCalculatorInterface = {
  ClientID: "ABC_Pty_Ltd",
  Token: "96a4fb51-4f4e-475f-bed9-5647a33e12c9",
  TestDomain: "www.lottrackr.com.au",
  CalcName: "BorrowingPower",
  JointIncome: "0",
  NoOfDependents: "0",
  InterestRate: "6.0",
  LoanTerm: "25 years",
  IncomeType: IncomeType.NET_INCOME,
  NetSalary1: "$70,000",
  NetSalaryFre1: Periodicity.YEARLY,
  NetSalary2: "$0",
  NetSalaryFre2: Periodicity.YEARLY,
  NetSalary3: "$70,000",
  NetSalaryFre3: Periodicity.YEARLY,
  Expense1: "$600",
  Expense2: "$5,000",
  Expense3: "$10,000",
  Expense4: "$2,400",
  Expense1Fre: Periodicity.MONTHLY,
  Expense2Fre: Periodicity.MONTHLY,
  Expense4Fre: Periodicity.MONTHLY
};

export const getRepaymentByFre = (fre: RepaymentFre, result: BorrowCalculatorResult) 
  : string | null => {
  switch(fre){
    case RepaymentFre.MONTHLY :
      return result.RepaymentMonthlyString;
    case RepaymentFre.FORTNIGHTLY :
      return result.RepaymentFortnightlyString;
    default: return result.RepaymentWeeklyString;
  }
}

export const getPeriodicityKey = (value: Periodicity) : string | undefined => {
  const keys = Object.keys(Periodicity) as (keyof typeof Periodicity)[];
  for (const key of keys) {
    if (Periodicity[key] === value) {
      return replaceUnderscoreWithSpaces(key.toLowerCase());
    }
  }
  return undefined;
}