import initialHoldApi from "@/services/api/initialHoldApi";
import { AxiosResponse } from "axios";
import { reactive } from "vue";
import { Module } from "vuex";
import InitialHoldStatus from "@/enums/initialHoldStatus";
import {
  CreateInitialHoldWithPartnerInterface,
  CreateInitialHoldInterface,
} from "@/types/InitialHoldInterface";

const state = reactive({
  pendingInitialHoldsCounter: 0,
});

export type initialHoldState = typeof state;

const initialHoldStore: Module<initialHoldState, never> = {
  namespaced: true,
  state,
  mutations: {
    setPendingInitialHoldsCounter(state, data): void {
      state.pendingInitialHoldsCounter = data;
    },
  },
  actions: {
    getInitialHolds(_, estateId: string): Promise<AxiosResponse> {
      return initialHoldApi.getInitialHolds({ estateId });
    },
    getActiveInitialHolds(_, id: string): Promise<AxiosResponse> {
      return initialHoldApi.getInitialHolds({
        estateId: id,
        status: [InitialHoldStatus.PENDING, InitialHoldStatus.APPROVED],
      });
    },
    getArchiveInitialHolds(_, id: string): Promise<AxiosResponse> {
      return initialHoldApi.getInitialHolds({
        estateId: id,
        status: [
          InitialHoldStatus.DECLINED,
          InitialHoldStatus.EXPIRED,
          InitialHoldStatus.CLOSED,
          InitialHoldStatus.CANCELLED,
        ],
      });
    },
    getInitialHoldsByStatus(_, data: { id: string, status : InitialHoldStatus[] })
      : Promise<AxiosResponse> {
      return initialHoldApi.getInitialHolds({
        estateId: data.id,
        status: data.status,
      });
    },
    getInitialHoldById(_, id: string): Promise<AxiosResponse> {
      return initialHoldApi.getInitialHoldById(id);
    },
    createInitialHold(
      _,
      data: CreateInitialHoldInterface | CreateInitialHoldWithPartnerInterface
    ): Promise<AxiosResponse> {
      return initialHoldApi.createInitialHold(data);
    },
    createInitialHoldFromLandownerSide(
      _,
      data: CreateInitialHoldInterface | CreateInitialHoldWithPartnerInterface
    ): Promise<AxiosResponse> {
      return initialHoldApi.createInitialHoldFromLandownerSide(data);
    },
    approveInitialHold(_, data): Promise<AxiosResponse> {
      return initialHoldApi.approveInitialHold(data);
    },
    declineInitialHold(
      _,
      data: { id: string; reasonForDecline: string }
    ): Promise<AxiosResponse> {
      return initialHoldApi.declineInitialHold(data);
    },
    cancelInitialHold(
      _,
      data: {
        id: string;
        reasonForCancel: string;
      }
    ): Promise<AxiosResponse> {
      return initialHoldApi.cancelInitialHold(data);
    },
    extendInitialHoldExpirationDate(
      _,
      data: { id: string; daysToExtend: number }
    ): Promise<AxiosResponse> {
      return initialHoldApi.extendInitialHoldExpirationDate(data);
    },
    getPendingInitialHoldsCounter(
      { commit },
      id: string
    ): Promise<AxiosResponse> {
      return initialHoldApi.getPendingInitialHoldsCount(id).then((res) => {
        commit("setPendingInitialHoldsCounter", res.data);
        return res;
      });
    },
    getApprovedInitialHoldsByLotsIds(
      _,
      lotIds: string[]
    ): Promise<AxiosResponse> {
      return initialHoldApi.getApprovedInitialHoldsByLotsIds({ lotIds });
    },
    setReasonForTermination(
      _,
      data: { id: string; reasonForTermination: string }
    ): Promise<AxiosResponse> {
      return initialHoldApi.setReasonForTermination(data);
    },
    getPendingInitialHoldsCountByLotId(
      _,
      lotId: string
    ): Promise<AxiosResponse> {
      return initialHoldApi.getPendingInitialHoldsCountByLotId(lotId);
    },
  },
};
export default initialHoldStore;
