import axiosClient from "@/services/api/index";
import { BorrowCalculatorInterface, BorrowCalculatorResult } from "@/types/BorrowCalculator";

export default {

  async getCalculatorResult(params: BorrowCalculatorInterface) : Promise<BorrowCalculatorResult> {
    return axiosClient.post('borrow-calculator', params)
           .then((res) => res.data)
           .catch((err) => Promise.reject(err));
  }
};
