import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-146a328c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "layout-container" }
const _hoisted_3 = { class: "wrapper-layout-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["HeaderCustomer"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              _createElementVNode("main", _hoisted_3, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}