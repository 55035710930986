import { UserInterface } from "@/types/UserInterface";
import store from "@/store";

export function isLogged(): boolean {
  return Boolean((store.state.user.data as UserInterface)._id);
}

export function getUser(): UserInterface {
  return store.state.user.data as UserInterface;
}
