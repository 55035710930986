import axiosClient from "@/services/api/index";
import { CreateFacadeInterface, FacadeInterface, QueryFacadeInterface, UpdateFacadeInterface } from "@/types/FacadeInterface";

export default {

  async createFacade(params: CreateFacadeInterface) : Promise<FacadeInterface> {
    return axiosClient.post('facade', params)
           .then((res) => res.data)
           .catch((err) => Promise.reject(err));
  },

  async uploadImageFacade(builderId: string, image: FormData) : Promise<unknown> {
    return axiosClient.post(`facade/images/${builderId}`, image)
           .then((res) => res.data)
           .catch((err) => Promise.reject(err));
  },

  async getFacadesByQuery(params:  QueryFacadeInterface) : Promise<FacadeInterface[]> {
    return axiosClient.get('facade', { params })
           .then((res) => res.data)
           .catch((err) => Promise.reject(err));
  },

  async deleteFacade(id:  string) : Promise<void> {
    return axiosClient.delete(`facade/${id}`)
           .then((res) => res.data)
           .catch((err) => Promise.reject(err));
  },

  async updateFacade(dto:  UpdateFacadeInterface, id: string) : Promise<void> {
    return axiosClient.patch(`facade/${id}`, dto)
           .then((res) => res.data)
           .catch((err) => Promise.reject(err));
  },
};
