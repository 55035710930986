export enum CustomizeHouseStepsEnum {
  LOT = "Select Lot",
  BUILDER = "Select Builder",
  HOUSE_DESIGN = "Select Design",
  EXTERIOR = "Select Exterior",
  CONFIRMATION = "Review",
}

export enum CustomizeHouseStepsReviewQuoteEnum {
  LOT = "Select Lot",
  BUILDER = "Select Builder",
  HOUSE_DESIGN = "Select Design",
  EXTERIOR = "Select Exterior",
  REVIEW_QUOTE = "Review Quote",
}
