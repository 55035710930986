import { UserRoles } from "@/enums/UserRoles";
import { RouteRecordRaw } from "vue-router";

const loadView = (path: string) => {
  return () =>
    import(/* webpackChunkName: "admin" */ `@/views/admin/${path}.vue`);
};
const metaSuperAdminAccess = {
  meta: {
    access: [UserRoles.SUPER_ADMIN],
  },
};

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    redirect: { name: "AdminUsers" },
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: loadView("AdminDashboard"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners",
    name: "AdminUsers",
    component: loadView("users/AdminUsers"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/lawyers",
    name: "AdminLawyers",
    component: loadView("users/AdminUsers"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/create",
    name: "AdminUsersCreate",
    component: loadView("users/AdminUserCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/lawyers/create",
    name: "AdminLawyerCreate",
    component: loadView("users/AdminUserCreate"),
    meta: {
      role: UserRoles.LAWYER,
      access: [UserRoles.SUPER_ADMIN],
    },
  },
  {
    path: "/admin/landowners/:userId",
    name: "AdminUser",
    component: loadView("users/AdminUser"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/lawyers/:userId",
    name: "AdminLawyer",
    component: loadView("users/AdminUser"),
    meta: {
      access: [UserRoles.SUPER_ADMIN],
      role: UserRoles.LAWYER,
    },
  },
  {
    path: "/admin/landowners/:userId/update",
    name: "AdminUserUpdate",
    component: loadView("users/AdminUserUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/lawyers/:userId/update",
    name: "AdminLawyerUpdate",
    component: loadView("users/AdminUserUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/update-landowner-details",
    name: "AdminLandownerDetailsUpdate",
    component: loadView("users/AdminLandownerDetailsUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/lawyers/:userId/update-lawyer-details",
    name: "AdminLawyerDetailsUpdate",
    component: loadView("users/AdminLandownerDetailsUpdate"),
    meta: {
      access: [UserRoles.SUPER_ADMIN],
      role: UserRoles.LAWYER,
    },
  },
  {
    path: "/admin/landowners/:userId/estate/create",
    name: "AdminEstateCreate",
    component: loadView("estates/AdminEstateCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId",
    name: "AdminEstate",
    component: loadView("estates/AdminEstate"),
    ...metaSuperAdminAccess,
    redirect: { name: "AdminEstateStages" },
    children: [
      {
        path: "stages",
        name: "AdminEstateStages",
        component: loadView("estates/AdminEstateStages"),
        ...metaSuperAdminAccess,
      },
      {
        path: "documents",
        name: "AdminEstateDocuments",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@/views/DocumentsView.vue"),
        ...metaSuperAdminAccess,
      },
      {
        path: "map",
        name: "AdminEstateMap",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@/components/EstateMap.vue"),
        ...metaSuperAdminAccess,
      },
    ],
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/update",
    name: "AdminEstateUpdate",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/profile/landowner/LandownerEstateUpdate.vue"
      ),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/lawyers/:userId/estate/:estateId/update",
    name: "AdminEstateUpdateLawyer",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/profile/landowner/LandownerEstateUpdate.vue"
      ),
    meta: {
      access: [UserRoles.SUPER_ADMIN],
      role: UserRoles.LAWYER,
    },
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/map/create",
    name: "AdminEstateMapCreate",
    component: loadView("estates/AdminEstateMapCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/map/update",
    name: "AdminEstateMapUpdate",
    component: loadView("estates/AdminEstateMapUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/create",
    name: "AdminStageCreate",
    component: loadView("stages/AdminStageCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId",
    name: "AdminStage",
    component: loadView("stages/AdminStage"),
    ...metaSuperAdminAccess,
    redirect: { name: "AdminStageLots" },
    children: [
      {
        path: "lots",
        name: "AdminStageLots",
        component: loadView("stages/AdminStageLots"),
        ...metaSuperAdminAccess,
      },
      {
        path: "map",
        name: "AdminStageMap",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@/components/StageMap.vue"),
        ...metaSuperAdminAccess,
      },
    ],
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/map/create",
    name: "AdminStageMapCreate",
    component: loadView("stages/AdminStageMapCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/map/update",
    name: "AdminStageMapUpdate",
    component: loadView("stages/AdminStageMapUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/update",
    name: "AdminStageUpdate",
    component: loadView("stages/AdminStageUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/lot/create",
    name: "AdminLotCreate",
    component: loadView("lots/AdminLotCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/lot/:lotId/update",
    name: "AdminLotUpdate",
    component: loadView("lots/AdminLotUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/lot/:lotId",
    name: "AdminLot",
    component: loadView("lots/AdminLot"),
    ...metaSuperAdminAccess,
    redirect: { name: "AdminLotDocuments" },
    children: [
      {
        path: "documents",
        name: "AdminLotDocuments",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@/views/lot/LotDocuments.vue"
          ),
        ...metaSuperAdminAccess,
      },
      {
        path: "map",
        name: "AdminLotMap",
        component: loadView("lots/AdminLotMap"),
        ...metaSuperAdminAccess,
      },
    ],
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/lot/:lotId/map/create",
    name: "AdminLotMapCreate",
    component: loadView("lots/AdminLotMapCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/landowners/:userId/estate/:estateId/stage/:stageId/lot/:lotId/map/update",
    name: "AdminLotMapUpdate",
    component: loadView("lots/AdminLotMapUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/settings",
    name: "AdminSettings",
    component: loadView("AdminSettings"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/builders",
    name: "AdminBuilders",
    component: loadView("builder/AdminBuilders"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/builders/create",
    name: "AdminBuilderCreate",
    component: loadView("builder/AdminBuilderCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/builders/update/:builderId",
    name: "AdminBuilderUpdate",
    component: loadView("builder/AdminBuilderUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/builders/:builderId",
    name: "AdminBuilder",
    component: loadView("builder/AdminBuilderDetails"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/builders/:builderId/house/create",
    name: "AdminBuilderCreateHouse",
    component: loadView("builder/AdminBuilderCreateHouse"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/builders/:builderId/house/update/:houseId",
    name: "AdminBuilderUpdateHouse",
    component: loadView("builder/AdminBuilderUpdateHouse"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/packages",
    name: "AdminPackages",
    component: loadView("concierge/AdminPackages"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/packages/create",
    name: "AdminPackageCreate",
    component: loadView("concierge/AdminPackageCreate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/packages/update/:packageId",
    name: "AdminPackageUpdate",
    component: loadView("concierge/AdminPackageUpdate"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/reports",
    name: "AdminReports",
    component: loadView("reports/AdminReports"),
    ...metaSuperAdminAccess,
  },
  {
    path: "/admin/reports/:stateId",
    name: "EstateGraphs",
    props: true,
    component: loadView("reports/EstateGraphs"),
    ...metaSuperAdminAccess,
  },
];
