import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { EoiStatus } from "@/enums/EoiStatus";
import { UserRoles } from "@/enums/UserRoles";
import { EoiInterface } from "@/types/EoiInterface";

export default {
  getEois(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("eois", { params });
  },
  getEoisGroupByLot(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("eois/group-by-lot", { params });
  },
  getEoisForLot(id: string, status: EoiStatus[]): Promise<AxiosResponse> {
    return axiosClient.get(`eois/group-by-lot/${id}`, { params: { status } });
  },
  getEoi(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`eois/${id}`);
  },
  createEoi(data: any): Promise<AxiosResponse> {
    return axiosClient.post("eois", data);
  },
  submitEoi(id: string, data: EoiInterface): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/submit/${id}`, data);
  },
  submitEoiForUnregisteredCustomer(
    id: string,
    data: EoiInterface
  ): Promise<AxiosResponse> {
    return axiosClient.patch(
      `eois/submit/for-unregistered-customer/${id}`,
      data
    );
  },
  submitZeroDepositEoi(id: string, data: EoiInterface): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/submit/zero-deposit/${id}`, data);
  },
  submitZeroDepositEoiForUnregisteredCustomer(
    id: string,
    data: EoiInterface
  ): Promise<AxiosResponse> {
    return axiosClient.patch(
      `eois/submit/for-unregistered-customer/zero-deposit/${id}`,
      data
    );
  },
  deleteEoi(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`eois/${id}`);
  },
  deleteEoiDocument(id: string, fileName: string): Promise<AxiosResponse> {
    return axiosClient.delete(`eois/documents/delete/${id}`, {
      params: { fileName },
    });
  },
  uploadDocument(id: string, data: FormData): Promise<AxiosResponse> {
    return axiosClient.post(`eois/buyer-document/upload/${id}`, data);
  },
  deleteDocument(params: {
    id: string;
    fileName: string;
  }): Promise<AxiosResponse> {
    return axiosClient.delete("eois/buyer-document/delete", {
      params: {
        id: params.id,
        fileName: params.fileName,
      },
    });
  },
  uploadDocumentForUnregisteredCustomer(
    id: string,
    data: FormData
  ): Promise<AxiosResponse> {
    return axiosClient.post(
      `eois/buyer-document/upload/for-unregistered-customer/${id}`,
      data
    );
  },
  deleteDocumentForUnregisteredCustomer(params: {
    id: string;
    fileName: string;
  }): Promise<AxiosResponse> {
    return axiosClient.delete(
      "eois/buyer-document/for-unregistered-customer/delete",
      {
        params: {
          id: params.id,
          fileName: params.fileName,
        },
      }
    );
  },
  downloadPdf(eoiId: string): Promise<AxiosResponse> {
    return axiosClient.get(`eois/download/pdf/${eoiId}`);
  },
  approveEoi(eoiId: string, lotId: string): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/approve/${eoiId}/${lotId}`);
  },
  revokeEoi(
    eoiId: string,
    lotId: string,
    reasonForTermination: string | null = null
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/revoke/${eoiId}/${lotId}`, {
      reasonForTermination,
    });
  },
  declineEoi({
    moneyHoldId,
    eoiId,
  }: {
    moneyHoldId: string;
    eoiId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/decline/${eoiId}`, {
      moneyHoldId,
    });
  },
  getEoiById(id: string): Promise<AxiosResponse> {
    return axiosClient.get("eois/" + id);
  },
  saveDraftEoi(id: string, data: any): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/save-draft/${id}`, data);
  },
  getEoisCustomerLots(customerId: string): Promise<AxiosResponse> {
    return axiosClient.get(`eois/customers-lot-list/${customerId}`);
  },
  updateEoi(id: string, data: any): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/update/${id}`, data);
  },
  eoiCounter(estateId: string): Promise<AxiosResponse> {
    return axiosClient.get(`eois/count-eoi/${estateId}`);
  },
  getEoiPaymentUrl(data: {
    eoiId: string;
    estateId: string;
    landownerId: string;
    lotId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post("/eois/payment-url", data);
  },
  getEoiPaymentUrlForUnregisteredCustomer(data: {
    eoiId: string;
    estateId: string;
    landownerId: string;
    lotId: string;
    customerId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post(
      "/eois/payment-url/for-unregistered-customer",
      data
    );
  },
  getCustomerLotList(id: string, params = {}): Promise<AxiosResponse> {
    return axiosClient.get(`eois/customers-lot-list/${id}`, { params });
  },
  eoiRefundApprove(id: string): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/refund/${id}/approve`);
  },
  eoiRefundDecline(id: string, data = {}): Promise<AxiosResponse> {
    return axiosClient.patch(`eois/refund/${id}/decline`, data);
  },
  getInvoice(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("invoices", { params });
  },
  getPaymentUrl(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`eois/payment-url-for-email/${id}`);
  },
  getDefaultTemplate() {
    return axiosClient.get("eois-template/default");
  },
  updateCustomTemplate(id: string, params = {}) {
    return axiosClient.patch(`eois-template/custom/${id}`, {
      groups: params,
    });
  },
  createCustomTemplate(params = {}) {
    return axiosClient.post("eois-template/custom", params);
  },
  getCustomTemplate(id: string) {
    return axiosClient.get(`eois-template/custom/${id}`);
  },
  sendEmailWithAttachments(data: {
    eoiId: string;
    email: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post(`eois/send/email-with-attachments/${data.eoiId}`, {
      email: data.email,
    });
  },
  createUnregisteredCustomer(params = {}): Promise<AxiosResponse> {
    return axiosClient.post("eois/create/for-unregistered-customer", params);
  },
};
