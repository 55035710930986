import axiosClient from './index';
import { AxiosResponse } from 'axios';
import { ContactEmail } from '@/types/ContactEmail';
import { CreateUser } from '@/types/CreateUser';
import {
  LandownerDetailsInterface,
  UserInterface,
} from '@/types/UserInterface';
import { AskAQuestionEmail } from '@/types/AskAQuestionEmail';

export default {
  login(payload: { password: string; login: string }): Promise<AxiosResponse> {
    return axiosClient.post('auth/sign-in', payload);
  },
  signUp(payload: {
    data: Record<string, string>;
    params: Record<string, string>;
  }): Promise<AxiosResponse> {
    return axiosClient.post('/auth/sign-up', payload.data, {
      params: payload.params,
    });
  },
  verify(token: string): Promise<AxiosResponse> {
    return axiosClient.get(`auth/verify/email/${token}`);
  },
  verifyUnregisteredCustomer(token: string): Promise<AxiosResponse> {
    return axiosClient.get(`auth/verify-unregistered/email/${token}`);
  },
  retryVerify(properties: Record<string, string>): Promise<AxiosResponse> {
    const { email, url, estateId } = properties;
    return axiosClient.get(`auth/retry/verify/email/${email}`, {
      params: {
        url,
        estateId,
      },
    });
  },
  forgotPassword(email: string): Promise<AxiosResponse> {
    return axiosClient.post('auth/forgot-password', { email });
  },
  changePassword(params: {
    token: string;
    password: string;
  }): Promise<AxiosResponse> {
    return axiosClient.put('auth/reset-password', params);
  },
  activatePassword(params: {
    token: string;
    password: string;
  }): Promise<AxiosResponse> {
    return axiosClient.put('auth/activate', params);
  },
  changePasswordForLoginUser(params: {
    oldPassword: string;
    newPassword: string;
  }): Promise<AxiosResponse> {
    return axiosClient.put('auth/change-password', params);
  },
  getMe(): Promise<AxiosResponse> {
    return axiosClient.get('user');
  },
  contactEmail(payload: ContactEmail): Promise<AxiosResponse> {
    return axiosClient.post('aws/send/contact-email', payload);
  },
  askAQuestionEmail(payload: AskAQuestionEmail): Promise<AxiosResponse> {
    return axiosClient.post('aws/send/ask-a-question', payload);
  },
  createUser(payload: CreateUser): Promise<AxiosResponse> {
    return axiosClient.post('users', payload);
  },
  getUsers(params = {}): Promise<AxiosResponse> {
    return axiosClient.get('users/elastic-search', { params });
  },
  getUser(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`users/${id}`);
  },
  getUsersSalesPerson(params = {}): Promise<AxiosResponse> {
    return axiosClient.get('users/sales-person', { params });
  },
  updateUser(payload: {
    id: string;
    data: Partial<UserInterface>;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`users/${payload.id}`, payload.data);
  },
  updateUserEmail(payload: {
    id: string;
    data: { email: string };
  }): Promise<AxiosResponse> {
    return axiosClient.put(`users/update-email/${payload.id}`, payload.data);
  },
  updateLandownerDetails(
    id: string,
    data: Partial<LandownerDetailsInterface>
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`landowner-details/${id}`, data);
  },
  updateLawyerDetails(
    id: string,
    data: Partial<LandownerDetailsInterface>
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`lawyer-details/${id}`, data);
  },
  logout(token: string): Promise<AxiosResponse> {
    return axiosClient.delete(`auth/logout/${token}`);
  },
  updateRefreshToken(refreshToken: string): Promise<AxiosResponse> {
    return axiosClient.get('auth/refreshToken', {
      params: {
        refreshToken,
      },
    });
  },
  updateTokens(): Promise<AxiosResponse> {
    return axiosClient.get('auth/update/tokens');
  },
  resendActivationEmail(data: {
    email: string;
    userId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.post('auth/resend-activation-email', data);
  },
  getStripeConnect(params = {}): Promise<AxiosResponse> {
    return axiosClient.get('stripe-connect/get-by-landowner', { params });
  },
  createStripeConnect(data: { landownerId: string }): Promise<AxiosResponse> {
    return axiosClient.post('stripe-connect', data);
  },
  updateStripeConnect(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`stripe-connect/update-stripe-connect/${id}`);
  },
  deleteUser(userId: string): Promise<AxiosResponse> {
    return axiosClient.delete(`users/${userId}`);
  },
  deleteLawyer(lawyerId: string): Promise<AxiosResponse> {
    return axiosClient.delete(`users/lawyer/${lawyerId}`);
  },
  createSalesPerson(params = {}): Promise<AxiosResponse> {
    return axiosClient.post('sales-person', params);
  },
  updateSalesPersonByLandowner(
    id: string,
    params = {}
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`sales-person/sales-person/${id}`, params);
  },
  updateSalesPersonProfile(params = {}): Promise<AxiosResponse> {
    return axiosClient.patch('sales-person', params);
  },
  deleteSalesPerson(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`sales-person/${id}`);
  },
  saveSelectedLotExtraColumns(data = []): Promise<AxiosResponse> {
    return axiosClient.patch('users/page-settings/update', {
      lotsTab: {
        showColumns: data,
      },
    });
  },
  getChargebeePortalSession(): Promise<AxiosResponse> {
    return axiosClient.get('landowner-details/chargebee/portal-session');
  },
};
