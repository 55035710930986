import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import stageApi from "@/services/api/stageApi";
import { CreateStage } from "@/types/CreateStage";
import { removeEmptyEntries } from "@/helpers/removeEmptyEntries";
import { CreateStageMapInterface } from "@/types/CreateStageMapInterface";

const state = reactive({});

export type stageState = typeof state;

const stageStore: Module<stageState, never> = {
  namespaced: true,
  state,
  actions: {
    getStages(_, params = {}): Promise<AxiosResponse> {
      return stageApi.getStages(removeEmptyEntries(params));
    },
    getStage(_, id: string): Promise<AxiosResponse> {
      return stageApi.getStage(id);
    },
    createStage(_, data: CreateStage): Promise<AxiosResponse> {
      return stageApi.createStage(data);
    },
    updateStage(
      _,
      payload: { id: string; data: Partial<CreateStage> }
    ): Promise<AxiosResponse> {
      return stageApi.updateStage(payload.id, payload.data);
    },
    deleteStage(_, id: string): Promise<AxiosResponse> {
      return stageApi.deleteStage(id);
    },
    getStagesMap(_, params = {}): Promise<AxiosResponse> {
      return stageApi.getStagesMap(params);
    },
    getStageMap(_, id: string): Promise<AxiosResponse> {
      return stageApi.getStageMap(id);
    },
    createStageMap(_, data: CreateStageMapInterface): Promise<AxiosResponse> {
      return stageApi.createStageMap(data);
    },
    updateStageMap(
      _,
      payload: { id: string; data: Partial<CreateStageMapInterface> }
    ): Promise<AxiosResponse> {
      return stageApi.updateStageMap(payload.id, payload.data);
    },
    uploadStageMapImg(
      _,
      payload: {
        landownerId: string;
        estateId: string;
        stageId: string;
        data: FormData;
      }
    ): Promise<AxiosResponse> {
      return stageApi.uploadStageMapImg(
        payload.landownerId,
        payload.estateId,
        payload.stageId,
        payload.data
      );
    },
    deleteStageMap(_, id: string): Promise<AxiosResponse> {
      return stageApi.deleteStageMap(id);
    },
  },
};

export default stageStore;
