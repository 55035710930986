import { createStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from "vuex-multi-tab-state";
import user, { userState } from "@/store/modules/user";
import estate, { estateState } from "@/store/modules/estate";
import stage, { stageState } from "@/store/modules/stage";
import lot, { lotState } from "@/store/modules/lot";
import eoi, { eoiState } from "@/store/modules/eoi";
import subscription, { subscriptionState } from "@/store/modules/subscription";
import contact, { contactState } from "@/store/modules/contact";
import customer, { customerState } from "@/store/modules/customer";
import userComments, { userCommentsState } from "@/store/modules/userComments";
import appSettings, { appSettingsState } from "@/store/modules/appSettings";
import activity, { activityState } from "@/store/modules/activity";
import listing, { listingState } from "./modules/listing";
import initialHold, { initialHoldState } from "./modules/initialHold";
import docusign, { docusignState } from "./modules/docusign";
import builder, { builderState } from "./modules/builder";
import rei, { reiState } from "./modules/rei";
import analytics, { analyticsState } from "./modules/analytics";
import borrowCalculator, { borrowCalculatorState } from "./modules/borrowCalculator";
import facade, { facadeState } from "./modules/facade";
import klaviyo, { klaviyoState } from "./modules/klaviyo";

export interface State {
  user: userState;
  estate: estateState;
  stage: stageState;
  lot: lotState;
  eoi: eoiState;
  subscription: subscriptionState;
  contact: contactState;
  customer: customerState;
  userComments: userCommentsState;
  appSettings: appSettingsState;
  activity: activityState;
  listing: listingState;
  initialHold: initialHoldState;
  docusign: docusignState;
  builder: builderState;
  rei: reiState;
  analytics: analyticsState;
  borrowCalculator: borrowCalculatorState;
  facade: facadeState;
  klaviyo: klaviyoState;
}

const store: Store<State> = createStore({
  modules: {
    user,
    estate,
    stage,
    lot,
    eoi,
    subscription,
    contact,
    customer,
    userComments,
    appSettings,
    activity,
    listing,
    initialHold,
    docusign,
    builder,
    rei,
    analytics,
    borrowCalculator,
    facade,
    klaviyo,
  },
  plugins: [
    createMultiTabState({ statesPaths: ["user"] }),
    createPersistedState({
      paths: ["user", "eoi", "docusign"],
    }),
  ],
});

export default store;
