import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import userCommentsApi from "@/services/api/userComments";

const state = reactive({});

export type userCommentsState = typeof state;

const userCommentsStore: Module<userCommentsState, never> = {
  namespaced: true,
  state,
  actions: {
    getComments(_, payload = {}): Promise<AxiosResponse> {
      return userCommentsApi.getComments(payload);
    },
    addComment(_, payload = {}): Promise<AxiosResponse> {
      return userCommentsApi.addComment(payload);
    },
    deleteComment(_, payload = {}): Promise<AxiosResponse> {
      return userCommentsApi.deleteComment(payload);
    },
    editComment(_, payload = {}): Promise<AxiosResponse> {
      return userCommentsApi.editComment(payload);
    },
  },
};

export default userCommentsStore;
