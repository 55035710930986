import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import subscriptionApi from "@/services/api/subscriptionApi";

const state = reactive({});

export type subscriptionState = typeof state;

const subscriptionStore: Module<subscriptionState, never> = {
  namespaced: true,
  state,
  actions: {
    notifyWaitlist(_, params = {}): Promise<AxiosResponse> {
      return subscriptionApi.notifyWaitlist(params);
    },
    getSubscription(_, params = {}): Promise<AxiosResponse> {
      return subscriptionApi.getSubscription(params);
    },
    getSubscriptionById(_, id: string): Promise<AxiosResponse> {
      return subscriptionApi.getSubscriptionById(id);
    },
    addToWaitlist(_, params = {}): Promise<AxiosResponse> {
      return subscriptionApi.addToWaitlist(params);
    },
    removeFromWaitlist(_, id: string): Promise<AxiosResponse> {
      return subscriptionApi.removeFromWaitlist(id);
    },
    updateSubscription(_, payload): Promise<AxiosResponse> {
      return subscriptionApi.updateSubscription(payload);
    },
    inviteToWaitlist(_, payload): Promise<AxiosResponse> {
      return subscriptionApi.inviteToWaitlist(payload);
    },
    inviteLeadToWaitlist(_, payload): Promise<AxiosResponse> {
      return subscriptionApi.inviteLeadToWaitlist(payload);
    },
    addSubscriptionForLead(_, params = {}): Promise<AxiosResponse> {
      return subscriptionApi.addSubscriptionForLead(params);
    },
    deleteSubscription(_, id: string): Promise<AxiosResponse> {
      return subscriptionApi.deleteSubscription(id);
    },
    checkSubscription(_, id: string): Promise<AxiosResponse> {
      return subscriptionApi.checkSubscription(id);
    },
    joinWaitlistFromPlans(_, params = {}): Promise<AxiosResponse> {
      return subscriptionApi.joinWaitlistFromPlans(params);
    },
    signUpFromPlans(_, params = {}): Promise<AxiosResponse> {
      return subscriptionApi.signUpFromPlans(params);
    },
  },
};

export default subscriptionStore;
