import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import docusignApi from "@/services/api/docusignApi";
import { DocusignUserData } from "@/types/DocusignUserData";

const state = reactive({
  userData: {
    isLogged: false,
    accessToken: "",
    expiresDate: null as string | null,
    profile: {
      accountId: "",
      baseUrl: "",
    },
  },
});

export type docusignState = typeof state;

const docusignStore: Module<docusignState, never> = {
  namespaced: true,
  state,
  mutations: {
    setUserData(states: docusignState, payload: DocusignUserData): void {
      if (!payload) {
        return;
      }
      states.userData = payload;
    },
    clearUserData(states: docusignState): void {
      states.userData = {
        isLogged: false,
        accessToken: "",
        expiresDate: null,
        profile: {
          accountId: "",
          baseUrl: "",
        },
      };
    },
  },
  actions: {
    docusignLogin(): Promise<AxiosResponse> {
      return docusignApi.login();
    },
    getTemplates(): Promise<AxiosResponse> {
      return docusignApi.getTemplates();
    },
    async createEnvelope(_, data): Promise<AxiosResponse> {
      return docusignApi.createEnvelope(data);
    },
    validateTemplate(_, data): Promise<AxiosResponse> {
      return docusignApi.validateTemplate(data);
    },
    getUserUrl(_, data): Promise<AxiosResponse> {
      return docusignApi.getUserUrl(data);
    },
    sendRequestAccessNotification(_, estateId: string): Promise<AxiosResponse> {
      return docusignApi.sendRequestAccessNotification(estateId);
    },
    getUserData(_, data): Promise<AxiosResponse> {
      return docusignApi.getUserData(data);
    },
  },
};

export default docusignStore;
