import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e947e88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "dropdown-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["slot-wrapper", { active: $setup.showDropDown }]),
      onClick: $setup.toggleDropdown
    }, [
      _renderSlot(_ctx.$slots, "control", {}, undefined, true)
    ], 2)), [
      [$setup["vClickOutside"], $setup.closeDropDown]
    ]),
    ($setup.showDropDown)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dropdown", $props.placement]),
          onClick: _withModifiers($setup.closeDropDown, ["stop"])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "dropdown", {}, undefined, true)
          ])
        ], 10, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}