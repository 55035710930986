export interface ObjectWithValues<T> {
  [key: string]: T | string;
}

export const checkObjectHasEmptyValues = <T>(
  obj: ObjectWithValues<T> | null | undefined
): boolean => {
  if (!obj) {
    return true;
  }
  return Object.values(obj).some(
    (item) => item === null || !item || item === ""
  );
};

export const checkObjectHasEmptyValuesAllowingZero = <T>(
  obj: ObjectWithValues<T> | null | undefined
): boolean => {
  if (!obj) {
    return true;
  }
  return Object.values(obj).some(
    (item) => item === null || item === undefined || item === ""
  );
};

