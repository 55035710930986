import { CustomizeHouseStepsEnum } from "@/enums/CustomizeHouseSteps";

export const userSelectionDefault : {
  id: number;
  floorplan: {
    url: string;
    name?: string;
  },
  currentStep: CustomizeHouseStepsEnum,
  isSelected: boolean;
  lot: {
    lotId: string;
    name: string;
    price: number;
  },
  builder: {
    id: string;
    name: string;
    logo: string;
  },
  house: {
    id: string;
    name: string;
    price: number;
  },
  kitchen: {
    color: string;
    name: string;
    url: string;
  },
  bathroom: {
    color: string;
    name: string;
    url: string;
  },
  facade: {
    id: string;
    name: string;
    url: string;
    price: number;
  },
} = {
  id: Date.now(),
  floorplan: {
    url: "",
    name: "",
  },
  currentStep: CustomizeHouseStepsEnum.LOT,
  isSelected: true,
  lot: {
    lotId: "",
    name: "",
    price: 0,
  },
  builder: {
    id: "",
    name: "",
    logo: "",
  },
  house: {
    id: "",
    name: "",
    price: 0,
  },
  kitchen: {
    color: "",
    name: "",
    url: "",
  },
  bathroom: {
    color: "",
    name: "",
    url: "",
  },
  facade: {
    id: "",
    name: "",
    url: "",
    price: 0,
  },
};
