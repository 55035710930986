import store from "@/store";

export const isInitialHoldDisabled = (): boolean => {
  const showInitialHold =
    store.state.user.landownerEstates.find(
      (estate) => estate._id === store.state.user.selectedEstateId
    )?.showInitialHold ?? false;

  return !showInitialHold;
};

export const getInitialHoldNavigation = () => {
  if (isInitialHoldDisabled()) {
    return [];
  }

  return [
    {
      title: "Hold Requests",
      icon: "icon-initial-hold.svg",
      link: "/initial-hold",
      slug: "initial-hold",
    },
  ];
};
