import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { CreateStage } from "@/types/CreateStage";
import { CreateStageMapInterface } from "@/types/CreateStageMapInterface";
import { StageParamInterface } from "@/types/StageInterface";

export default {
  getStages(payload: StageParamInterface): Promise<AxiosResponse> {
    const params = payload;
    if (!params.estateId) {
      params.estateId = "";
    }
    return axiosClient.get("stages", { params });
  },
  getStage(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`stages/${id}`);
  },
  createStage(data: CreateStage): Promise<AxiosResponse> {
    return axiosClient.post("stages", data);
  },
  updateStage(id: string, data: Partial<CreateStage>): Promise<AxiosResponse> {
    return axiosClient.patch(`stages/${id}`, data);
  },
  deleteStage(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`stages/${id}`);
  },
  getStagesMap(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("maps/stages", { params });
  },
  getStageMap(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`maps/stages/get-by-stage-id/${id}`);
  },
  createStageMap(data: CreateStageMapInterface): Promise<AxiosResponse> {
    return axiosClient.post("maps/stages", data);
  },
  updateStageMap(
    id: string,
    data: Partial<CreateStageMapInterface>
  ): Promise<AxiosResponse> {
    return axiosClient.patch(`maps/stages/${id}`, data);
  },
  uploadStageMapImg(
    landownerId: string,
    estateId: string,
    stageId: string,
    data: FormData
  ): Promise<AxiosResponse> {
    return axiosClient.post(
      `maps/stages/image/${landownerId}/${estateId}/${stageId}`,
      data
    );
  },
  deleteStageMap(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`maps/stages/${id}`);
  },
};
