import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";

export default {
  notifyWaitlist(params = {}): Promise<AxiosResponse> {
    return axiosClient.post("subscriptions/notify", params);
  },
  getSubscription(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("subscriptions", { params });
  },
  getSubscriptionById(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`/subscriptions/${id}`);
  },
  addToWaitlist(params = {}): Promise<AxiosResponse> {
    return axiosClient.post("subscriptions", params);
  },
  removeFromWaitlist(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`subscriptions/${id}`);
  },
  updateSubscription(payload: {
    id: string;
    params: Record<string, unknown>;
  }): Promise<AxiosResponse> {
    const { id, params } = payload;
    return axiosClient.patch(`subscriptions/${id}`, params);
  },
  inviteToWaitlist(payload: {
    id: string;
    params: Record<string, unknown>;
  }): Promise<AxiosResponse> {
    const { id, params } = payload;
    return axiosClient.post(`subscriptions/invite-to-waitlist/${id}`, params);
  },
  inviteLeadToWaitlist(payload: {
    id: string;
    leadsId: string[];
  }): Promise<AxiosResponse> {
    return axiosClient.post("subscriptions/invite-leads", payload);
  },
  addSubscriptionForLead(params = {}) {
    return axiosClient.post("subscriptions/subscription-for-lead", params);
  },
  deleteSubscription(id: string): Promise<AxiosResponse> {
    return axiosClient.delete(`subscriptions/${id}`);
  },
  checkSubscription(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`subscriptions/check-subscription/${id}`);
  },
  joinWaitlistFromPlans(params = {}): Promise<AxiosResponse> {
    return axiosClient.post("subscriptions/plans/waitlist", params);
  },
  signUpFromPlans(params = {}): Promise<AxiosResponse> {
    return axiosClient.post("subscriptions/plans/sign-up", params);
  },
};
