import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { AnalyticsDataInterface } from "@/types/AnalyticsDataInterface";

export default {
  getReport(data: AnalyticsDataInterface): Promise<AxiosResponse> {
    return axiosClient.post("analytics", data);
  },

  getReportLeadsConversions(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("analytics/leads-conversions", { params });
  },

  getReportBuyerTypes(params = {}): Promise<AxiosResponse> {
    return axiosClient.get(`analytics/initial-hold/buyer-type/`, { params });
  },

  getReportByInitialHold(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("analytics/initial-hold/contracts-signed", {
      params,
    });
  },

  getReportLotStatus(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("analytics/lot-grouped-by-status", {
      params,
    });
  },

  getReportLotPrice(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("analytics/lot-grouped-by-price", {
      params,
    });
  },

  getBuyerTypesPostcodeAndState(params = {}): Promise<AxiosResponse> {
    return axiosClient.get(`analytics/initial-hold/state-and-postcode`, { params });
  },
};
