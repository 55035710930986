import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import customerApi from "@/services/api/customerApi";
import { CustomerDetails } from "@/types/CustomerDetails";

const state = reactive({});

export type customerState = typeof state;

const customerStore: Module<customerState, never> = {
  namespaced: true,
  state,
  actions: {
    updateCustomerDetails(_, payload: { id: string; data: Partial<CustomerDetails> }
    ): Promise<AxiosResponse> {
      return customerApi.updateCustomerDetails(payload);
    },
    getCustomerDetails(_, params = {} as { id: string }): Promise<AxiosResponse> {
      return customerApi.getCustomerDetails(params);
    },
    uploadCustomerDocument(_, params: FormData): Promise<AxiosResponse> {
      return customerApi.uploadCustomerDocument(params);
    },
    removeCustomerDocument(_, params: { id: string; name: string }) {
      return customerApi.removeCustomerDocument(params);
    },
  },
};

export default customerStore;
