import listingApi from '@/services/api/listingApi';
import { reactive } from 'vue';
import { Module } from 'vuex';

const state = reactive({});

export type listingState = typeof state;

const listingStore: Module<listingState, never> = {
  namespaced: true,
  state,
  actions: {
    getListings(_, params = {}) {
      return listingApi.getListings(params);
    },
    getListing(_, id) {
      return listingApi.getListing(id);
    },
    desactivateListing(_, data) {
      return listingApi.desactivateListing(data);
    },
    activateListing(_, data) {
      return listingApi.activateListing(data);
    },
    uploadImages(_, data) {
      return listingApi.uploadImages(data);
    },
    createListing(_, data) {
      return listingApi.createListing(data);
    },
    updateListing(_, data) {
      return listingApi.updateListing(data);
    },
    soldListing(_, data) {
      return listingApi.soldListing(data);
    },
    parseXmlToListings(_, data) {
      return listingApi.parseXmlToListings(data);
    },
    createImportedListings(_, data) {
      return listingApi.createImportedListings(data);
    }
  },
};
export default listingStore;
