import { ListingStatus } from '@/enums/ListingStatus';
import { removeEmptyEntries } from '@/helpers/removeEmptyEntries';
import axiosClient from '@/services/api/index';
import { ListingInterface, ListingUpdate } from '@/types/ListingInterface';
import { AxiosResponse } from 'axios';
export default {
  getListings(
    payload = {} as { status: ListingStatus; estateId: string }
  ): Promise<AxiosResponse> {
    const params = removeEmptyEntries({
      status: payload.status,
      estateId: payload.estateId,
    });
    return axiosClient.get('listing', { params });
  },
  getListing(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`listing/${id}`);
  },
  desactivateListing(data: { id: string; agentId: string }): Promise<AxiosResponse> {
    return axiosClient.patch(`listing/deactivate/${data.id}`, data);
  },
  activateListing(data: { id: string; agentId: string }): Promise<AxiosResponse> {
    return axiosClient.patch(`listing/pusblish/${data.id}`, data);
  },
  uploadImages(data: any[]): Promise<AxiosResponse> {
    return axiosClient.post("listing/upload", data);
  },
  createListing(data: ListingInterface): Promise<AxiosResponse> {
    return axiosClient.post("listing", data);
  },
  updateListing(data: ListingUpdate) {
    return axiosClient.patch(`listing/${data.id}`, data.data);
  },
  soldListing(data: ListingUpdate) {
    return axiosClient.patch(`listing/sold/${data.id}`, data.data);
  },
  parseXmlToListings(payload: {
    landownerId: string;
    estateId: string;
    data: FormData;
  }): Promise<AxiosResponse> {
    return axiosClient.post(
      `listing/import/parse-xml/landowner/${payload.landownerId}/estate/${payload.estateId}`,
      payload.data
    );
  },
  createImportedListings(payload: {
    listings: ListingInterface[];
  }): Promise<AxiosResponse> {
    return axiosClient.post(`listing/import/create`, payload);
  },
};
