import { reactive } from "vue";
import { Module } from "vuex";
import { borrowCalculatorDefault, BorrowCalculatorInterface, BorrowCalculatorResult } from "@/types/BorrowCalculator";
import borrowCalculatorApi from "@/services/api/borrowCalculatorApi";
import { HouseFiltersFields } from "@/types/HouseFilterFieldsInterface";

const state = reactive(
  {
    ...borrowCalculatorDefault,
    filterFields: {
      budget: 'Any',
      bedrooms: 'Any',
      bathrooms: 'Any',
      storeys: 'Any',
      lotId: null,
    } as HouseFiltersFields,
  },
);

export type borrowCalculatorState = typeof state;

const borrowCalculatorStore: Module<borrowCalculatorState, never> = {
  namespaced: true,
  state,
  mutations: {
    updateFilter(state, filterFields: HouseFiltersFields) {
      state.filterFields = { ...state.filterFields, ...filterFields };
    },
  },
  getters: {
    getFilterFields: state => state.filterFields,
  },
  actions: {
    getCalculatorResult(_, params: BorrowCalculatorInterface): Promise<BorrowCalculatorResult> {
      return borrowCalculatorApi.getCalculatorResult(params);
    },
  },
};

export default borrowCalculatorStore;
