import { getInitialHoldNavigation } from "@/helpers/initialHold/isInitialHoldDisabled";

export const navigation = [
  {
    title: "Dashboard",
    icon: "icon-dashboard.svg",
    link: "/dashboard",
    slug: "dashboard",
  },
  {
    title: "Lots",
    icon: "icon-lots.svg",
    link: "/lots",
    slug: "lots",
  },
  {
    title: "Documents",
    icon: "icon-folder.svg",
    link: "/documents",
    slug: "documents",
  },
  {
    title: "Contract forms",
    icon: "icon-eoi.svg",
    link: "/eoi",
    slug: "eoi",
  },
  {
    title: "Waitlist",
    icon: "icon-waitlist.svg",
    link: "/waitlist",
    slug: "waitlist",
  },
  {
    title: "Leads",
    icon: "icon-leads.svg",
    link: "/leads",
    slug: "leads",
  },
  {
    title: "Contacts",
    icon: "icon-contacts.svg",
    link: "/contacts",
    slug: "contacts",
  },
  {
    title: "Listings",
    icon: "icon-listing.svg",
    link: "/listing",
    slug: "listing",
  },
];

export const adminNavigation = [
  {
    title: "Dashboard",
    icon: "icon-dashboard.svg",
    link: "/admin/dashboard",
    slug: "dashboard",
    soon: true,
  },
  {
    title: "Land Owners",
    icon: "icon-contacts.svg",
    link: "/admin/landowners",
    slug: "landowners",
  },
  {
    title: "Trust Accounts",
    icon: "icon-contacts.svg",
    link: "/admin/lawyers",
    slug: "lawyers",
  },
  {
    title: "Builders",
    icon: "icon-contacts.svg",
    link: "/admin/builders",
    slug: "builders",
  },
  {
    title: "Concierge",
    icon: "icon-contacts.svg",
    link: "/admin/packages",
    slug: "packages",
  },
  {
    title: "Settings",
    icon: "icon-settings.svg",
    link: "/admin/settings",
    slug: "settings",
    class: "icon-gray",
  },
];

export const customerNavigation = [
  {
    label: "My Lots",
    link: "/customer/lots",
    slug: "lots",
    icon: "icon-lots-black.svg",
  },
  // functionality is limited
  // {
  //   label: "My Documents",
  //   link: "/customer/documents",
  //   slug: "documents",
  //   icon: "folder.svg",
  // },
  {
    label: "Waitlists",
    link: "/customer/waitlist",
    slug: "waitlists",
    icon: "icon-waitlist-black.svg",
  },
  {
    label: "Profile",
    link: "/customer/profile",
    slug: "profile",
    icon: "icon-profile-black.svg",
  },
];

export const logOutCustomerNavigation = [
  {
    label: "Log in",
    icon: "icon-login-black.svg",
    emit: "login",
  },
  {
    label: "Create account",
    icon: "icon-create-account-black.svg",
    emit: "register",
  },
  {
    label: "Register your interest",
    icon: "icon-flag-black.svg",
    emit: "interest",
  },
  {
    label: "Contact Us",
    icon: "icon-contact-black.svg",
    emit: "contact",
  },
];

export const landownerProfileNavigation = [
  {
    title: "Account",
    icon: "icon-dashboard.svg",
    link: "/landowner/profile/general",
    slug: "general",
  },

  {
    title: "Projects",
    icon: "icon-lots.svg",
    link: "/landowner/profile/estates",
    slug: "estates",
  },

  {
    title: "Users",
    icon: "icon-sales-person.svg",
    link: "/landowner/profile/users",
    slug: "users",
  },

  {
    title: "Subscriptions",
    icon: "icon-subscription.svg",
    link: "/landowner/profile/subscriptions-billing",
    slug: "subscriptions-billing",
  },
];

export const salesPersonNavigation = [
  {
    title: "Account",
    icon: "icon-dashboard.svg",
    link: "/landowner/profile/general",
    slug: "general",
  },

  {
    title: "Projects",
    icon: "icon-lots.svg",
    link: "/landowner/profile/estates",
    slug: "estates",
  },
];

export const builderCalculatorNavigation = [
  {
    label: "Calculate my Borrowing Capacity",
    icon: "icon-login-black.svg",
    emit: "calculate",
  },
];
