import { computed } from 'vue';
import { UserRoles } from '@/enums/UserRoles';
import { useStore } from 'vuex';

export default () => {
  const store = useStore();
  const userData = store.state.user.data;
  const userRole = userData.role;

  const isSalesPersonRole = computed(() => userRole === UserRoles.SALES_PERSON);

  const isLandownerRole = computed(() => userRole === UserRoles.LANDOWNER);

  const isSuperAdmin = computed(() => userRole === UserRoles.SUPER_ADMIN);

  const isCustomer = computed(() => userRole === UserRoles.CUSTOMER);

  return {
    isSalesPersonRole,
    isLandownerRole,
    isSuperAdmin,
    isCustomer,
  };
};
