export enum PlansFormEnum {
  WAITLIST = "waitlist",
  SIGN_UP = "sign-up",
}

export enum PlansButtonFormEnum {
  JOIN_WAITLIST = "Join Waitlist",
  GET_STARTED = "Get Started",
}

export enum PlansStoreFormEnum {
  JOIN_WAITLIST = "joinWaitlistFromPlans",
  GET_STARTED = "signUpFromPlans",
}
