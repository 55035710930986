import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { CustomerDetails } from "@/types/CustomerDetails";

export default {
  uploadCustomerDocument(params = {}): Promise<AxiosResponse> {
    return axiosClient.post("customer-details/documents/upload", params);
  },
  getCustomerDetails(params = {} as { id: string }): Promise<AxiosResponse> {
    return axiosClient.get(`customer-details/${params.id}`);
  },
  removeCustomerDocument(
    params = {} as { id: string; name: string }
  ): Promise<AxiosResponse> {
    return axiosClient.delete(`customer-details/documents/delete`, { params });
  },
  updateCustomerDetails(payload: {
    id: string;
    data: Partial<CustomerDetails>;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`customer-details/${payload.id}`, payload.data);
  },
};
