export enum EoiStatus {
  PENDING = "pending",
  APPROVED = "approved",
  DECLINED = "declined",
  TEMP = "temp",
  DRAFT = "draft",
  PENDING_FOR_BILLING = "pending for billing",
  TERMINATED = "terminated",
  TRANSACTION_ERROR = "transaction_error", // declined before being pending
  WAITING_FOR_APPROVED = "waitingForApproved", // when the system calls stripe API and waiting for a callback
  WAITING_FOR_DECLINED = "waitingForDeclined", // when the system calls stripe API and waiting for a callback
  WITHDRAWN = "withdrawn", // withdrawn after being approved, waiting for refund
  REFUNDED = "refunded", // refunded money after eoi was withdrawn
  REFUND_DECLINED = "refundDeclined", // refund money was declined
  WAITING_FOR_PENDING = "waitingForPending",
}
