import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import { AnalyticsDataInterface } from "@/types/AnalyticsDataInterface";
import analyticsApi from "@/services/api/analyticsApi";
import InitialHoldStatus from "@/enums/initialHoldStatus";

const state = reactive({});

export type analyticsState = typeof state;

const analyticsStore: Module<analyticsState, never> = {
  namespaced: true,
  state,
  actions: {
    createReport(_, data: AnalyticsDataInterface): Promise<AxiosResponse> {
      return analyticsApi.getReport(data);
    },

    getLeadsConversions(
      _,
      query: {
        startDate: string;
        endDate: string;
        landownerId: string;
        estateSelected: string;
      }
    ): Promise<AxiosResponse> {
      return analyticsApi.getReportLeadsConversions(query);
    },

    getBuyerTypes(
      _,
      query: {
        landownerId: string;
        startDate: string;
        endDate: string;
        estateSelected: string;
      }
    ): Promise<AxiosResponse> {
      return analyticsApi.getReportBuyerTypes(query);
    },

    getInitialHold(
      _,
      query: {
        landownerId: string;
        status: InitialHoldStatus;
        estateSelected: string;
      }
    ): Promise<AxiosResponse> {
      return analyticsApi.getReportByInitialHold(query);
    },

    getLotStatus(
      _,
      query: {
        landownerId: string;
        estateSelected: string;
        startDate: string;
        endDate: string;
      }
    ): Promise<AxiosResponse> {
      return analyticsApi.getReportLotStatus(query);
    },

    getLotPrice(
      _,
      query: {
        landownerId: string;
        estateSelected: string;
        startDate: string;
        endDate: string;
      }
    ): Promise<AxiosResponse> {
      return analyticsApi.getReportLotPrice(query);
    },

    getBuyerTypesPostcodeAndState(
      _,
      query: {
        landownerId: string;
        startDate: string;
        endDate: string;
        estateSelected: string;
      }
    ): Promise<AxiosResponse> {
      return analyticsApi.getBuyerTypesPostcodeAndState(query);
    },
  },
};

export default analyticsStore;
