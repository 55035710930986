import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34d9b678"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-short-panel" }
const _hoisted_2 = { class: "sidebar-short-content" }
const _hoisted_3 = { class: "estates" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "sidebar-short-footer" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!$setup.isAdmin)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.estates, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["estate unselectable", { 'estate--active': $setup.checkActiveEstate(item), 'estate--notification': false }]),
                key: index,
                onClick: ($event: any) => ($setup.onEstate(item)),
                style: _normalizeStyle({ background: item.iconColor })
              }, _toDisplayString(item.title.charAt(0)), 15, _hoisted_4))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.account, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "sidebar-short-footer__item",
            key: index,
            onClick: ($event: any) => ($setup.iconClick(item.action))
          }, [
            _withDirectives(_createElementVNode("img", {
              src: require(`../assets/icons/${item.icon}`),
              class: _normalizeClass(item.iconClass),
              alt: "icon"
            }, null, 10, _hoisted_7), [
              [_directive_tippy, { content: item.tooltipText, theme: 'light-border', placement: 'right' }]
            ])
          ], 8, _hoisted_6))
        }), 128))
      ])
    ])
  ]))
}