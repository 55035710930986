import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import activityApi from "@/services/api/activityApi";

const state = reactive({});

export type activityState = typeof state;

const activityStore: Module<activityState, never> = {
  namespaced: true,
  state,
  actions: {
    getInvoicesByCustomer(_, payload = {}): Promise<AxiosResponse> {
      return activityApi.getInvoicesByCustomer(payload);
    }
  },
};

export default activityStore;
