import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { EoiInterface } from "@/types/EoiInterface";

export default {
  getTemplates(): Promise<AxiosResponse> {
    return axiosClient.get("rei/forms");
  },
  createForm({
    template_id,
    name,
    eoi,
  }: {
    template_id: string;
    name: string;
    eoi: EoiInterface;
  }): Promise<AxiosResponse> {
    return axiosClient.post("rei/form", {
      template_id,
      name,
      eoi,
    });
  },
};
