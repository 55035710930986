import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";

export default {
  getComments(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("user-comments", { params });
  },
  addComment(payload = {}): Promise<AxiosResponse> {
    return axiosClient.post("user-comments", payload);
  },
  deleteComment({
    id,
    estateId,
  }: {
    id: string;
    estateId: string;
  }): Promise<AxiosResponse> {
    return axiosClient.delete(`user-comments/${id}`, {
      params: {
        estateId
      }
    });
  },
  editComment({
    id,
    estateId,
    text,
  }: {
    id: string;
    estateId: string;
    text: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`user-comments/${id}`, { estateId, text });
  },
};
